import React from 'react'

export const CommonBanner = (props) => {
    return (
        <div className='container-fluid'>
            <div className='container-fluid commonBgImage'>
            </div>
            <div className="commonBgText">
                <h1 style={{ "fontSize": "50px" }} className="fadeInDown-animation">{props.pageName}</h1>
                <p style={{ "fontSize": "22px" }} className="fadeInUp-animation">This is {props.pageName} Page</p>
            </div>
            <hr/>
        </div>
    )
}
