import React from 'react'
import { Link } from 'react-router-dom'

export const Banner = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6 mt-5">
                    <h5 className="bannerSubHeading fadeInDown-animation">Welcome To Talent Center</h5>
                    <h1 className="bannerHeading my-5 fadeInDown-animation">    Your <br />
                        Recruitment<span className="partnerColor">Partner</span></h1>
                    <p className="paragraphStyle my-5 fadeInDown-animation">
                        Facilisi commodo ac consequat erat risus duis velit quis velit fermentum feugiat sit bibendum pellentesque semper maecenas posuere cursus gravida.
                    </p>

                    <Link type="button" className="btn btn-dark btn-lg shadow btnHoverEffect fadeInUp-animation" to="/contact">Get Started</Link>
                </div>
                <div className="col-sm-6 mt-5">
                    <img src={process.env.PUBLIC_URL + "/assets/webImages/Hero-Section-Image.jpg"} className="continueUpDown-animation img-fluid" alt="" />
                </div>
            </div>
        </div>
    )
}
