import React from 'react'
import { Link } from 'react-router-dom'

export const Blog = () => {
    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-sm-4'>
                    <Link to="/article/why-do-candidates-back-out-how-to-tackle-it">
                        <div className="card img-hover-zoom img-hover-zoom--colorize w-75 mx-auto d-block my-3 shadow">
                            <img src={process.env.PUBLIC_URL + "/assets/webImages/dummy/no-image-available.jpg"} className="card-img" height="375" alt="..." />
                            <div className="card-img-overlay">
                                <h5 className="card-title mt-5">
                                    <button className="btn btn-dark btnHoverEffect rounded-0 mt-5">ARTICLE / RESOURCES</button>
                                </h5>
                                <p className="card-text paragraphStyle fw-bloder my-3">Why Do Candidates Back Out? How To Track It?</p>
                                <p className="card-text"><small>17/01/2022</small></p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='col-sm-4'>
                    <Link to="/article/recruitment-methods-traditional-vs-modern-Whats-the-best">
                        <div className="card img-hover-zoom img-hover-zoom--colorize w-75 mx-auto d-block my-3 shadow">
                            <img src={process.env.PUBLIC_URL + "/assets/webImages/dummy/no-image-available.jpg"} className="card-img" height="375" alt="..." />
                            <div className="card-img-overlay">
                                <h5 className="card-title mt-5">
                                    <button className="btn btn-dark btnHoverEffect rounded-0 mt-5">ARTICLE / RESOURCES</button>
                                </h5>
                                <p className="paragraphStyle fw-bloder my-3">Recruitment Methods: TRADITIONAL vs MODERN
                                    What’s the best?</p>
                                <p className="card-text"><small>17/01/2022</small></p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='col-sm-4'>
                    <Link to="/article/want-to-be-a-successful-recruiter-here-is-how">
                        <div className="card img-hover-zoom img-hover-zoom--colorize w-75 mx-auto d-block my-3 shadow">
                            <img src={process.env.PUBLIC_URL + "/assets/webImages/dummy/no-image-available.jpg"} className="card-img" height="375" alt="..." />
                            <div className="card-img-overlay">
                                <h5 className="card-title mt-5">
                                    <button className="btn btn-dark btnHoverEffect rounded-0 mt-5">ARTICLE / RESOURCES</button>
                                </h5>
                                <p className="paragraphStyle fw-bloder my-3">Want To Be A Successful Recruiter? Here's Is How!</p>
                                <p className="card-text"><small>17/01/2022</small></p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
