import React from 'react'
import OwlCarousel from 'react-owl-carousel';

export const Testimonial = () => {
    const option = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoPlay: true,
        timeout: 2000,
        dots: true,
        smartSpeed: 450,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1170: {
                items: 3,
            },
        },
    }

    return (
        <div className="container-fluid my-5">
            <h5 className="bannerSubHeading mt-5 text-center fadeInUp-animation ">Testimonial</h5>
            <h1 className="fw-bold mt-3 mb-5 text-center fadeInUp-animation ">What People Say</h1>

            <div className="row text-center">
                <section className="testimonials">
                    <div className="container my-5">
                        <div className="row">
                            <div className="col-sm-12">
                                <OwlCarousel id="customers-testimonials" className="owl-carousel owl-theme" {...option}>
                                    <div className="item m-3">
                                        <div className="card shadow-lg p-5 border-0 rounded-4">
                                            <div className="card-body">
                                                <h4 className="card-title">Kelly Smith</h4>
                                                <h5 style={{ "color": "#ff5722" }}>client</h5>
                                                <p className="card-text paragraphStyle">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, pariatur! Laborum architecto suscipit est. Maiores error aspernatur porro modi laudantium, repudiandae cum? Placeat, exercitationem aut!</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="item m-3">
                                        <div className="card shadow-lg p-5 border-0 rounded-4">
                                            <div className="card-body">
                                                <h4 className="card-title">Kelly Smith</h4>
                                                <h5 style={{ "color": "#ff5722" }}>client</h5>
                                                <p className="card-text paragraphStyle">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, pariatur! Laborum architecto suscipit est. Maiores error aspernatur porro modi laudantium, repudiandae cum? Placeat, exercitationem aut!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item m-3">
                                        <div className="card shadow-lg p-5 border-0 rounded-4">
                                            <div className="card-body">
                                                <h4 className="card-title">Kelly Smith</h4>
                                                <h5 style={{ "color": "#ff5722" }}>client</h5>
                                                <p className="card-text paragraphStyle">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, pariatur! Laborum architecto suscipit est. Maiores error aspernatur porro modi laudantium, repudiandae cum? Placeat, exercitationem aut!</p>
                                            </div>
                                        </div>

                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="row text-center">
                <h1 className="fw-bold mt-3 mb-5 text-center">Insta feed</h1>
            </div>
        </div>
    )
}
