import React from 'react'
import { Link } from 'react-router-dom'

export const Ready = () => {
    return (
        <div className="container-fluid p-5">
            <div className="row border rounded-5 letStart">
                <div className="col-sm-6 fadeLeftIn-animation">
                    <h5 className="ps-5 pt-3 fs-4">Are You Ready For</h5>
                    <h2 className="ps-5 fs-1 fw-bold text-light">LETS GET STARTED</h2>
                    <h4 className="ps-5 pb-3 fs-2 text-light">YOUR PROJECT</h4>
                </div>
                <div className="col-sm-6 text-end my-5 pe-5 fadeRightIn-animation">
                    <Link className="btn btn-dark btn-lg px-5 py-3 my-2 btnHover" to="/contact">Get Started</Link>
                </div>
            </div>
        </div>
    )
}
