import React from 'react'
import { Link } from 'react-router-dom'

export const Solution = () => {
    return (
        <div className="container-fluid my-5">
            <h5 className="bannerSubHeading text-center fadeInUp-animation ">Our Solutions</h5>
            <h1 className="fw-bold mt-3 mb-5 text-center fadeInUp-animation "> What We Do</h1>

            <div className="container text-center">
                <div className="row justify-content-center">
                    <div className="col-sm-5">
                        <div className="card cardStyle my-3 p-5 fadeLeftIn-animation" style={{ "backgroundColor": "#005ba6", "borderRadius": "0px 50px 50px 50px" }}>
                            <h1 className="text-light text-center my-3">Pay-Per-Hire</h1>
                            <p className="text-light paragraphStyle text-center my-3">
                                We manage all recruitment processes end-to-end.
                            </p>
                            <p className="text-light paragraphStyle text-center my-3">
                                Pay Per Hire follows the commission model where clients pay recruitment fees for the result.
                            </p>
                            <div className="text-center">
                                <Link type="button" className="btn btn-dark btn-lg shadow btnHoverEffect" to="/our-services">Get Started</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div className="card cardStyle my-3 p-5 fadeRightIn-animation" style={{ "backgroundColor": "#00ad66", "borderRadius": "50px 0px 50px 50px" }}>
                            <h1 className="text-light text-center my-3">Pro-Hire</h1>
                            <p className="text-light paragraphStyle text-center my-3">
                                Subscription-based full-cycle recruitment service with worldwide coverage.
                            </p>
                            <p className="text-light paragraphStyle text-center my-3">
                                Imagine recruiting process outsourcing that adopts your hiring needs.
                            </p>
                            <div className="text-center">
                                <Link type="button" className="btn btn-dark btn-lg shadow btnHoverEffect" to="/our-services">Get Started</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
