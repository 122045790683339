import React from 'react'

export const Jobs = () => {
    return (
        <div className='container-fluid my-5'>
            <div className='row my-2'>
                <div className="text-center text-bg-light p-4 shadow">
                    <div className="text-start fw-bold mb-4" style={{ color: "#005ba6" }}>
                        <h3>Showroom Manager</h3>
                    </div>
                    <div className="card-body">
                        <div className='row text-start'>
                            <div className='col-sm-2'>
                                <h5>Job Code</h5>
                                <p>BMJ1640</p>
                            </div>
                            <div className='col-sm-2'>
                                <h5>Work Experience</h5>
                                <p>1years -2years</p>
                            </div>
                            <div className='col-sm-2'>
                                <h5>Salary</h5>
                                <p>₹2,40,000 - ₹3,00,000 P.A</p>
                            </div>
                            <div className='col-sm-3'>
                                <h5>Skills</h5>
                                <p>managing inventory, preparing reports, and supervising sales staff</p>
                            </div>
                            <div className='col-sm-3'>
                                <h5>Location</h5>
                                <p>Bhubaneswar (Rasulgarh) - Odisha , India</p>
                            </div>
                        </div>

                    </div>
                    <div className="text-muted text-end">
                        <button type='button' className='btn btn-sm btn-warning shadow'>View & Apply</button>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="text-center text-bg-light p-4 shadow">
                    <div className="text-start fw-bold mb-4" style={{ color: "#005ba6" }}>
                        <h3>Store Manager</h3>
                    </div>
                    <div className="card-body">
                        <div className='row text-start'>
                            <div className='col-sm-2'>
                                <h5>Job Code</h5>
                                <p>BMJ1639</p>
                            </div>
                            <div className='col-sm-2'>
                                <h5>Work Experience</h5>
                                <p>1years -2years</p>
                            </div>
                            <div className='col-sm-2'>
                                <h5>Salary</h5>
                                <p>₹2,40,000 - ₹3,00,000 P.A</p>
                            </div>
                            <div className='col-sm-3'>
                                <h5>Skills</h5>
                                <p>Store management, billing, record keeping</p>
                            </div>
                            <div className='col-sm-3'>
                                <h5>Location</h5>
                                <p>Balasore - Odisha , India</p>
                            </div>
                        </div>

                    </div>
                    <div className="text-muted text-end">
                        <button type='button' className='btn btn-sm btn-warning shadow'>View & Apply</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
