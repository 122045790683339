import * as Yup from "yup";

export const signUpSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string().min(7).max(12).required("Please enter your password"),
});

export const candidateExperienceSchema = Yup.object({
  action: Yup.string().required("Some internal problem"),
  user_id: Yup.string().required("Some internal problem"),
  first_name: Yup.string().required("Please enter first name"),
  dob: Yup.string().required("Please enter dob"),
  gender: Yup.string().required("Please select gender"),
  mobile: Yup.number().required("Please enter mobile number"),
  email: Yup.string().email().required("Please enter valid email"),
  country_id: Yup.string().required("Please select country name"),
  state_id: Yup.string().required("Please select state name"),
  city_id: Yup.string().required("Please select city name"),
  // experience_type: Yup.string().required("Please select experience type"),

  work_experience_in_year: Yup.number().max(45, "Year must not exceed 45").required("Please enter year of experience"),
  work_experience_in_month: Yup.number().max(11, "Month must not exceed 11").required("Please enter month of experience"),
  designation_name: Yup.string().required("Please enter designation"),
  current_company_name: Yup.string().required("Please enter current company name"),
  remote_status: Yup.string().required("Please selct remote status"),
  job_type: Yup.string().required("Please selct job type problem"),
  notice_period: Yup.number().max(180, "Notice period must not exceed 180 days").required("Please enter notice period in days"),
  vertical_id: Yup.string().required("Please select vertical"),
  ctc_type: Yup.string().required("Please select CTC type"),
  current_ctc_lakhs: Yup.number().min(1, "CTC must be above 1 Lakhs").required("Please enter ctc in lakhs"),
  current_ctc_thausands: Yup.number().required("Please enter ctc in month"),
  skills: Yup.string().min(5).required("Please enter skills"),
  preferred_location: Yup.string().required("Please enter preferred location"),

  upload_file: Yup.mixed()
    .required("Resume is required")
    .test("fileSize", "File size is too large", (value) => value && value.size <= 1024 * 1024 * 5) // 2 MB limit
    .test("fileType", "Invalid file type", (value) => value && ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(value.type)),
});

export const candidateFresherSchema = Yup.object({
  action: Yup.string().required("Some internal problem"),
  user_id: Yup.string().required("Some internal problem"),
  first_name: Yup.string().required("Please enter first name"),
  dob: Yup.string().required("Please enter dob"),
  gender: Yup.string().required("Please select gender"),
  mobile: Yup.number().required("Please enter mobile number"),
  email: Yup.string().email().required("Please enter valid email"),
  country_id: Yup.string().required("Please select country name"),
  state_id: Yup.string().required("Please select state name"),
  city_id: Yup.string().required("Please select city name"),
  // experience_type: Yup.string().required("Please select experience type"),

  remote_status: Yup.string().required("Please selct remote status"),
  notice_period: Yup.number().max(180, "Notice period must not exceed 180 days").required("Please enter notice period in days"),
  skills: Yup.string().min(5).required("Please enter skills"),
  preferred_location: Yup.string().required("Please enter preferred location"),

  upload_file: Yup.mixed()
    .required("Resume is required")
    .test("fileSize", "File size is too large", (value) => value && value.size <= 1024 * 1024 * 5) // 2 MB limit
    .test("fileType", "Invalid file type", (value) => value && ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(value.type)),
});

export const candidateExperienceEditSchema = Yup.object({
  action: Yup.string().required("Some internal problem"),
  user_id: Yup.string().required("Some internal problem"),
  first_name: Yup.string().required("Please enter first name"),
  dob: Yup.string().required("Please enter dob"),
  gender: Yup.string().required("Please select gender"),
  mobile: Yup.number().required("Please enter mobile number"),
  email: Yup.string().email().required("Please enter valid email"),
  country_id: Yup.string().required("Please select country name"),
  state_id: Yup.string().required("Please select state name"),
  city_id: Yup.string().required("Please select city name"),
  // experience_type: Yup.string().required("Please select experience type"),

  work_experience_in_year: Yup.number().max(45, "Year must not exceed 45").required("Please enter year of experience"),
  work_experience_in_month: Yup.number().max(11, "Month must not exceed 11").required("Please enter month of experience"),
  designation_name: Yup.string().required("Please enter designation"),
  current_company_name: Yup.string().required("Please enter current company name"),
  remote_status: Yup.string().required("Please selct remote status"),
  job_type: Yup.string().required("Please selct job type problem"),
  notice_period: Yup.number().max(180, "Notice period must not exceed 180 days").required("Please enter notice period in days"),
  vertical_id: Yup.string().required("Please select vertical"),
  ctc_type: Yup.string().required("Please select CTC type"),
  current_ctc_lakhs: Yup.number().min(1, "CTC must be above 1 Lakhs").required("Please enter ctc in lakhs"),
  current_ctc_thausands: Yup.number().required("Please enter ctc in month"),
  skills: Yup.string().min(5).required("Please enter skills"),
  preferred_location: Yup.string().required("Please enter preferred location"),

  upload_file: Yup.mixed()
    .test("fileSize", "File size must be under 5 mb", (value) => {
      if (value === undefined) {
        return true;
      } else if (value.size <= 1024 * 1024 * 5) {
        return true;
      } else {
        return false;
      }
    })
    .test("fileType", "Upload pdf, doc, docx files", (value) => {
      if (value === undefined) {
        return true;
      } else if (["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(value.type)) {
        return true;
      } else {
        return false;
      }
    }),
});

export const candidateFresherEditSchema = Yup.object({
  action: Yup.string().required("Some internal problem"),
  user_id: Yup.string().required("Some internal problem"),
  first_name: Yup.string().required("Please enter first name"),
  dob: Yup.string().required("Please enter dob"),
  gender: Yup.string().required("Please select gender"),
  mobile: Yup.number().required("Please enter mobile number"),
  email: Yup.string().email().required("Please enter valid email"),
  country_id: Yup.string().required("Please select country name"),
  state_id: Yup.string().required("Please select state name"),
  city_id: Yup.string().required("Please select city name"),
  // experience_type: Yup.string().required("Please select experience type"),

  remote_status: Yup.string().required("Please selct remote status"),
  notice_period: Yup.number().max(180, "Notice period must not exceed 180 days").required("Please enter notice period in days"),
  skills: Yup.string().min(5).required("Please enter skills"),
  preferred_location: Yup.string().required("Please enter preferred location"),

  upload_file: Yup.mixed()
    .test("fileSize", "File size must be under 5 mb", (value) => {
      if (value === undefined) {
        return true;
      } else if (value.size <= 1024 * 1024 * 5) {
        return true;
      } else {
        return false;
      }
    })
    .test("fileType", "Upload pdf, doc, docx files", (value) => {
      if (value === undefined) {
        return true;
      } else if (["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(value.type)) {
        return true;
      } else {
        return false;
      }
    }),
});

export const roleManagementSchema = Yup.object({
  action: Yup.string().required("Some internal problem"),
  user_type: Yup.string().required("Please select user type"),
  permission_id: Yup.string().required("Please select permission"),
  role_name: Yup.string().required("Please enter role name"),
});

export const userManagementSchema = Yup.object({
  action: Yup.string().required("Some internal problem"),
  main_entity_type: Yup.string().required("Please select entity type"),
  role_id: Yup.string().required("Please select role name"),
  entity_id: Yup.string().required("Please select entity name"),
  first_name: Yup.string().required("Please enter first name"),
  last_name: Yup.string().required("Please enter last name"),
  gender: Yup.string().required("Please choose gender"),
  mobile: Yup.number().required("Please enter mobile number"),
  email: Yup.string().email().required("Please enter valid email"),
  password: Yup.string().required("Please enter password"),
});

export const entityManagementSchema = Yup.object().shape({
  action: Yup.string().required("Some internal problem"),
  main_entity_type: Yup.string().required("Please select user entity type"),
  entity_name: Yup.string().required("Please entity name"),
  entity_ligal_name: Yup.string().required("Please entity legal name"),
  entity_type: Yup.string().required("Please entity type"),
  entity_status: Yup.string().required("Please select entity status"),
  business_entity_id: Yup.string().required("Please select business entity name"),
  signed_contract: Yup.string().required("Please select signed contract"),
  email: Yup.string()
    .test("is-valid", "Invalid email address", function (value) {
      const { createError, path } = this;
      if (!value || value.trim().length === 0) {
        return true; // No validation if field is empty
      }
      if (!Yup.string().email().isValidSync(value)) {
        return createError({ path, message: "Invalid email address" });
      }
      return true;
    })
    .when("$always", {
      is: true,
      then: Yup.string().required("Email is required"),
    }),

  mobile: Yup.string()
    .test("is-valid", "Invalid mobile number", function (value) {
      if (!value || value.trim().length === 0) {
        return true; // No validation if field is empty
      }
      const isValidMobile = /^\d{10}$/.test(value); // Check if mobile number consists of 10 digits
      return isValidMobile;
    })
    .when("$always", {
      is: true,
      then: Yup.string().required("Mobile number is required"),
    }),
});
