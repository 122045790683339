import React from 'react'

export const Technology = () => {
    return (
        <div className="container-fluid my-5">
            <div className="row">
                <div className="col-sm-6">
                    <h5 className="bannerSubHeading mt-5 fadeInUp-animation ">Our Technology</h5>
                    <h1 className="fw-bold mt-3 mb-5 fadeInUp-animation ">What Is An Applicant <br /> Tracking System (ATS)?</h1>
                    <p className="paragraphStyle"> <span className="fw-bold">Applicant Tracking System</span> (ATS) software provides recruiting tools that help recruiters find qualified candidates by filtering, organizing and streamlining job applications. Eliminating unqualified candidates when resumes arrive is the main purpose of ATS so that recruiters spend their time on candidates who are more likely to fit the position. </p>
                </div>
                <div className="col-sm-6 text-end">
                    <img src={process.env.PUBLIC_URL + "/assets/webImages/atsresize-min.png"} className="img-fluid" alt="" />
                </div>
                <div className="col-sm-12">
                    <p className="paragraphStyle">An applicant tracking system (ATS) is a software application that enables the electronic handling of recruitment and hiring needs. An ATS is very similar to customer relationship management (CRM) systems, but are designed for recruitment tracking purposes. In many cases, they filter applications automatically based on given criteria such as keywords, skills, former employers, years of experience and schools attended.</p>
                </div>

            </div>
        </div>
    )
}
