import React from 'react'

export const TalentBenifits = () => {
    return (
        <div className="container-fluid my-5">
            <h5 className="bannerSubHeading mt-5 text-center fadeInUp-animation ">Talent Center's Benefits</h5>
            <h1 className="fw-bold mt-3 mb-5 text-center fadeInUp-animation "> How Talent Center <br /> Benefits Employers </h1>

            <div className="row">
                <div className="col-sm-3">
                    <div className="card cardHover m-1 p-5">
                        <img src={process.env.PUBLIC_URL + "/assets/webImages/back-in-time.png"} className="mx-auto d-block my-4" alt="" width="120" height="120" />
                        <h3 style={{ "color": "#005BA6" }}>SAVE TIME IN HIRING A TALENT</h3>
                        <p className="paragraphStyle">At Talent Center, we enhance your hiring efficiency, freeing you to concentrate on long-term organizational success. Our specialized team aids StartUps and MSMEs with timely talent support.</p>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="card cardHover m-1 p-5">
                        <img src={process.env.PUBLIC_URL + "/assets/webImages/management1.png"} className="mx-auto d-block my-4" alt="" width="120" height="120" />
                        <h3 style={{ "color": "#005BA6" }}>FOCUS ON DELIVERABLES</h3>
                        <p className="paragraphStyle">We approach every stage of the recruitment process – from identifying potential candidates to final onboarding – with meticulous care and full alignment with our clients' needs..</p>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="card cardHover m-1 p-5">
                        <img src={process.env.PUBLIC_URL + "/assets/webImages/reduce-cost.png"} className="mx-auto d-block my-4" alt="" width="120" height="120" />
                        <h3 style={{ "color": "#005BA6" }}>REDUCE OF COST HIRING</h3>
                        <p className="paragraphStyle">You will be free from cost associated with screening of resume, scheduling and conducting interviews, shortlisting the candidates and background verifications.</p>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="card cardHover m-1 p-5">
                        <img src={process.env.PUBLIC_URL + "/assets/webImages/quality.png"} className="mx-auto d-block my-4" alt="" width="120" height="120" />
                        <h3 style={{ "color": "#005BA6" }}>QUALITY OF HIRE</h3>
                        <p className="paragraphStyle">Huge database of profiles, well experienced talent acquisition team, advanced technologies and well maintained process will make sure the employer will have the best talent at the right qualities.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
