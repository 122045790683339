import React from 'react'

export const Referral = () => {
  return (
    <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </>
  )
}