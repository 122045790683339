import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import axios from "axios";
import { toast } from 'react-toastify';
import DataTable from "react-data-table-component";
import Modal from 'react-bootstrap/Modal';
import { useFormik } from "formik";
import { entityManagementSchema } from '../../schemas';
import Swal from 'sweetalert2'

export const EntityManagement = (props) => {

    const initialValues = {
        action: "createClient",
        user_id: JSON.parse(sessionStorage.getItem("user"))['user_id'],
        main_entity_type: "",
        entity_name: "",
        entity_ligal_name: "",
        entity_type: "",
        entity_status: "",
        account_manager: "",
        business_entity_id: "",
        signed_contract: "",
        contract_expired: "",
        website: "",
        email: "",
        mobile: "",
        linkedin_url: "",
        facebook_url: "",
        twitter_url: "",
        industry_id: "",
        vertical_id: "",
        gstin: "",
        CIN: "",
        pan_number: "",
        tan_number: "",
        registered_address: "",
        corporate_address: "",
        company_profile: "",
        additional_note: "",
    }
    const [action, setAction] = useState("createClient");

    const [datePicker, setDatePicker] = useState(null);
    const [businessEntity, setBusinessEntity] = useState([]);
    const [industry, setIndustry] = useState([]);
    const [vertical, setVertical] = useState([]);
    const [filterVertical, setFilterVertical] = useState([]);
    const [entityDetails, setEntityDetails] = useState([]);
    const [filterDatas, setFilterDatas] = useState([]);
    const [filterEntityDetailsForView, setFilterEntityDetailsForView] = useState([]);
    const [runUseEffect, setRunUseEffect] = useState(false);
    const [search, setSearch] = useState("");
    const [showHide, setShowHide] = useState(false);
    const [modalShowHide, setModalShowHide] = useState(false);
    const [addModalShowHide, setAddModalShowHide] = useState(false);

    const userTypeHierarchy = {
        1: "ASSOCIATE",
        2: "CLIENT",
        3: "AGENCY",
    }


    const closeModal = () => {
        setShowHide(false);
        resetForm();
        setAddModalShowHide(false);
        setAction("createClient");
        setDatePicker(null);
    }


    const { values, errors, touched, setFieldValue, handleChange, handleSubmit, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: entityManagementSchema,
        onSubmit: (values) => {
            axios({
                url: props.axiosURL,
                method: "POST",
                data: values,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                console.log(res.data);
                const status = res.data['status'];
                if (status === 1) {
                    if (action === "createClient") {
                        toast.success("Data Inserted Successfully", { autoClose: 3000 })
                    } else {
                        toast.success("Data Updated Successfully", { autoClose: 3000 })
                    }
                    setRunUseEffect(!runUseEffect);
                    closeModal();
                } else {
                    toast.error(res.data['msg'], { autoClose: 3000 })
                }
            }).catch((error) => {
                console.log(error);
            })
        }
    });


    useEffect(() => {
        // get business entity details
        axios({
            url: props.axiosURL,
            method: "POST",
            data: { action: "viewAllbusinessEntity" },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            // console.log(res.data);
            const roleCount = res.data['count'];
            if (roleCount > 0) {
                setBusinessEntity(res.data['data'])
            }
        }).catch((error) => {
            console.log(error);
        })

        // get industry details
        axios({
            url: props.axiosURL,
            method: "POST",
            data: { action: "viewAllIndustries" },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            // console.log(res.data);
            const roleCount = res.data['count'];
            if (roleCount > 0) {
                setIndustry(res.data['data'])
            }
        }).catch((error) => {
            console.log(error);
        })

        // get vertical details
        axios({
            url: props.axiosURL,
            method: "POST",
            data: { action: "viewAllVerticals" },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            // console.log(res.data);
            const roleCount = res.data['count'];
            if (roleCount > 0) {
                setVertical(res.data['data'])
                // setFilterVertical(res.data['data'])
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [props.axiosURL])

    useEffect(() => {
        // get all client details
        axios({
            url: props.axiosURL,
            method: "POST",
            data: { action: "viewAllClient" },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            console.log(res.data);
            const roleCount = res.data['count'];
            if (roleCount > 0) {
                setEntityDetails(res.data['data'])
                setFilterDatas(res.data['data'])
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [props.axiosURL, runUseEffect])

    const convertDateFormat = (inputDate) => {
        console.log(inputDate)
        if (inputDate !== null && inputDate !== "") {
            const parts = inputDate.split('-');
            const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
            return new Date(formattedDate);
        } else {
            return new Date()
        }
    }

    const fetchFotEdit = (entity_id) => {
        setShowHide(true);
        setAddModalShowHide(true)
        // console.log(entity_id);
        const filterEntityDetails = entityDetails.filter((value) => {
            return value.entity_id === entity_id;
        })[0]
        setFilterVertical(vertical.filter((data) => {
            return data.industry_id === filterEntityDetails.industry_id
        }))
        setAction("updateClient")
        console.log(filterEntityDetails.contract_expired);
        if (filterEntityDetails.contract_expired === "") {
            setDatePicker(null);
        } else {
            setDatePicker(convertDateFormat(filterEntityDetails.contract_expired));
        }

        // setDatePicker(convertDateFormat(filterEntityDetails.contract_expired));
        setFieldValue("action", "updateClient")
        setFieldValue("edited_by", JSON.parse(sessionStorage.getItem("user"))['user_id'])
        setFieldValue("main_entity_type", filterEntityDetails.main_entity_type)
        setFieldValue("entity_id", entity_id)
        setFieldValue("entity_name", filterEntityDetails.entity_name)
        setFieldValue("entity_ligal_name", filterEntityDetails.entity_ligal_name)
        setFieldValue("entity_type", filterEntityDetails.entity_type)
        setFieldValue("entity_status", filterEntityDetails.entity_status)
        setFieldValue("account_manager", filterEntityDetails.account_manager)
        setFieldValue("business_entity_id", filterEntityDetails.business_entity_id)
        setFieldValue("signed_contract", filterEntityDetails.signed_contract)
        setFieldValue("contract_expired", filterEntityDetails.contract_expired)
        setFieldValue("website", filterEntityDetails.website)
        setFieldValue("email", filterEntityDetails.email)
        setFieldValue("mobile", filterEntityDetails.mobile)
        setFieldValue("linkedin_url", filterEntityDetails.linkedin_url)
        setFieldValue("facebook_url", filterEntityDetails.facebook_url)
        setFieldValue("twitter_url", filterEntityDetails.twitter_url)
        setFieldValue("industry_id", filterEntityDetails.industry_id)
        setFieldValue("vertical_id", filterEntityDetails.vertical_id)
        setFieldValue("gstin", filterEntityDetails.gstin)
        setFieldValue("CIN", filterEntityDetails.CIN)
        setFieldValue("pan_number", filterEntityDetails.pan_number)
        setFieldValue("tan_number", filterEntityDetails.tan_number)
        setFieldValue("registered_address", filterEntityDetails.registered_address)
        setFieldValue("corporate_address", filterEntityDetails.corporate_address)
        setFieldValue("company_profile", filterEntityDetails.company_profile)
        setFieldValue("additional_note", filterEntityDetails.additional_note)
    }

    const deleteData = async (entity_id) => {

        const result = await Swal.fire({
            title: "Are you sure?",
            text: "Do you want to Delete",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
        });

        if (result.isConfirmed) {
            axios({
                url: props.axiosURL,
                method: "POST",
                data: { action: "deleteClient", entity_id: entity_id, user_id: JSON.parse(sessionStorage.getItem("user"))['user_id'] },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                // console.log(res.data);
                const status = res.data['status'];
                if (status === 1) {
                    toast.success("Client Deleted Successfully", { autoClose: 3000 })
                    setRunUseEffect(!runUseEffect);
                } else {
                    toast.error(res.data['msg'], { autoClose: 3000 })
                }
            }).catch((error) => {
                console.log(error);
            })
        }

    }

    const viewDataOnModal = (entity_id) => {
        setModalShowHide(true)
        setFilterEntityDetailsForView(entityDetails.filter((value) => {
            return value.entity_id === entity_id;
        })[0])
    }


    const columns = [
        {
            name: "Status",
            grow: 0,
            selector: (row) => row.entity_status === "1" ? ("ACTIVE") : row.entity_status === "2" ? ("INACTIVE") : row.entity_status === "3" ? ("PENDING") : ("N/A"),
            sortable: true,
            wrap: true,
        }, {
            name: "Entity Type",
            grow: 0,
            selector: (row) => userTypeHierarchy[row.main_entity_type],
            sortable: true,
            wrap: true,
        },
        {
            name: "Entity Name",
            selector: (row) => row.entity_name,
            sortable: true,
            wrap: true,
        },
        {
            name: "Client Business Name",
            selector: (row) => row.entity_ligal_name,
            sortable: true,
            wrap: true,
        },
        {
            name: "Industry Name",
            selector: (row) => row.industry_name,
            sortable: true,
            wrap: true,
        },
        {
            name: "Since",
            selector: (row) => row.created_on,
            sortable: true,
            wrap: true,
        },
        {
            name: "Action",
            grow: 2,
            cell: (row) => (
                <>
                    <button className="btn btn-sm btn-success me-1" onClick={() => viewDataOnModal(row.entity_id)}>
                        View Details
                    </button>
                    <button className="btn btn-sm btn-warning me-1" onClick={() => fetchFotEdit(row.entity_id)}>
                        Edit
                    </button>
                    <button className="btn btn-sm btn-danger" onClick={() => deleteData(row.entity_id)}>
                        Delete
                    </button>
                </>
            ),
        },
    ]
    useEffect(() => {
        const result = entityDetails.filter((item) => {
            return item.entity_ligal_name.toLowerCase().match(search.toLowerCase());
        });
        setFilterDatas(result);
    }, [entityDetails, search]);


    return (
        <div className='container-fluid'>
            <div className="row">

                <Modal show={modalShowHide} size="" onHide={() => setModalShowHide(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div><span><span className='fw-bolder'>ENTITY TYPE : </span>{userTypeHierarchy[filterEntityDetailsForView.main_entity_type]}</span></div>
                        <div><span><span className='fw-bolder'>ENTITY NAME : </span>{filterEntityDetailsForView.entity_name}</span></div>
                        <div><span><span className='fw-bolder'>ENTITY LEGAL NAME(AS PER GST)</span>{filterEntityDetailsForView.entity_ligal_name}</span></div>
                        <div><span><span className='fw-bolder'>ENTITY TYPE : </span>
                            {
                                filterEntityDetailsForView.entity_type === "1" ? ("Client Of Client") :
                                    filterEntityDetailsForView.entity_type === "2" ? ("Direct Client") :
                                        filterEntityDetailsForView.entity_type === "3" ? ("Employer") :
                                            filterEntityDetailsForView.entity_type === "4" ? ("Recruitment Agency") :
                                                filterEntityDetailsForView.entity_type === "5" ? ("Referral Partner") :
                                                    filterEntityDetailsForView.entity_type === "6" ? ("Staffing/Recruitment Agency") :
                                                        filterEntityDetailsForView.entity_type === "7" ? ("Vendor Partner") :
                                                            ("")
                            }
                        </span></div>
                        <div><span><span className='fw-bolder'>BUSINESS STATUS : </span>
                            {
                                filterEntityDetailsForView.entity_status === "1" ? ("Active") :
                                    filterEntityDetailsForView.entity_status === "2" ? ("Inactive") :
                                        filterEntityDetailsForView.entity_status === "3" ? ("Pending") :
                                            ("")
                            }
                        </span></div>
                        <div><span><span className='fw-bolder'>ACCOUNT MANAGER : </span>{filterEntityDetailsForView.account_manager}</span></div>
                        <div><span><span className='fw-bolder'>BUSINESS ENTITY TYPE : </span>{filterEntityDetailsForView.business_entity_name}</span></div>
                        <div><span><span className='fw-bolder'>SIGNED CONTRACT : </span>
                            {
                                filterEntityDetailsForView.signed_contract === "1" ? ("Yes") :
                                    filterEntityDetailsForView.signed_contract === "2" ? ("No") :
                                        filterEntityDetailsForView.signed_contract === "3" ? ("Inprogress") :
                                            filterEntityDetailsForView.signed_contract === "4" ? ("Subscription") :
                                                ("")
                            }
                        </span></div>
                        <div><span><span className='fw-bolder'>CONTRACT EXPIRE DATE : </span>{filterEntityDetailsForView.contract_expired}</span></div>
                        <div><span><span className='fw-bolder'>WEBSITE (http(s)://www.abc.com) : </span>{filterEntityDetailsForView.website}</span></div>
                        <div><span><span className='fw-bolder'>EMAIL : </span>{filterEntityDetailsForView.email}</span></div>
                        <div><span><span className='fw-bolder'>MOBILE : </span>{filterEntityDetailsForView.mobile}</span></div>
                        <div><span><span className='fw-bolder'>LINKEDIN URL : </span>{filterEntityDetailsForView.linkedin_url}</span></div>
                        <div><span><span className='fw-bolder'>FACEBOOK URL : </span>{filterEntityDetailsForView.facebook_url}</span></div>
                        <div><span><span className='fw-bolder'>TWITER URL : </span>{filterEntityDetailsForView.twitter_url}</span></div>
                        <div><span><span className='fw-bolder'>INDUSTRY : </span>{filterEntityDetailsForView.industry_name}</span></div>
                        <div><span><span className='fw-bolder'>VERTICAL / SEGMENT : </span>{filterEntityDetailsForView.vertical_name}</span></div>
                        <div><span><span className='fw-bolder'>DEFAULT GSTIN : </span>{filterEntityDetailsForView.gstin}</span></div>
                        <div><span><span className='fw-bolder'>CIN : </span>{filterEntityDetailsForView.CIN}</span></div>
                        <div><span><span className='fw-bolder'>PAN CARD NUMBER : </span>{filterEntityDetailsForView.pan_number}</span></div>
                        <div><span><span className='fw-bolder'>TAN NUMBER : </span>{filterEntityDetailsForView.tan_number}</span></div>
                        <div><span><span className='fw-bolder'>REGISTERED ADDRESS : </span>{filterEntityDetailsForView.registered_address}</span></div>
                        <div><span><span className='fw-bolder'>CORPORATE ADDRESS : </span>{filterEntityDetailsForView.corporate_address}</span></div>
                        <div><span><span className='fw-bolder'>COMPANY PROFILE : </span>{filterEntityDetailsForView.company_profile}</span></div>
                        <div><span><span className='fw-bolder'>ADDITIONAL NOTES : </span>{filterEntityDetailsForView.additional_note}</span></div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-warning btn-lg shadow shadow-lg me-2" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={() => setModalShowHide(false)} >Close</button>
                    </Modal.Footer>
                </Modal>


                <Modal show={addModalShowHide} size="xl" onHide={closeModal}>
                    <Modal.Header closeButton>
                        <h1 className="text-center my-3">ADD ENTITY DETAILS</h1>
                    </Modal.Header>
                    <Modal.Body>

                        <form method="POST">
                            <div className="row p-5">
                                <h1 className="text-center py-3">ENTITY MANAGEMENT</h1>
                                <div className="form-group col-3">
                                    <label className='fw-bold my-2' htmlFor="USER TYPE">USER TYPE</label>
                                    <select className="form-control shadow" name="main_entity_type" value={values.main_entity_type} onChange={handleChange}>
                                        <option value="">SELECT</option>
                                        {
                                            Object.keys(userTypeHierarchy).map((value, key) => {
                                                return (
                                                    <option value={value} key={key}>{userTypeHierarchy[value]}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {errors.main_entity_type && touched.main_entity_type ? (<p className="text-danger">{errors.main_entity_type}</p>) : null}
                                </div>

                                <div className="form-group col-3">
                                    <label className="fw-bold my-2" htmlFor="ENTITY NAME">ENTITY NAME</label>
                                    <input type="text" name="entity_name" id="" className="form-control shadow" placeholder="" value={values.entity_name} onChange={handleChange} aria-describedby="helpId" />
                                    {errors.entity_name && touched.entity_name ? (<p className="text-danger">{errors.entity_name}</p>) : null}
                                </div>
                                <div className="form-group col-3">
                                    <label className="fw-bold my-2" htmlFor="ENTITY LEGAL NAME(AS PER GST)">ENTITY LEGAL NAME(AS PER GST)</label>
                                    <input type="text" name="entity_ligal_name" id="" className="form-control shadow" placeholder="" value={values.entity_ligal_name} onChange={handleChange} aria-describedby="helpId" />
                                    {errors.entity_ligal_name && touched.entity_ligal_name ? (<p className="text-danger">{errors.entity_ligal_name}</p>) : null}
                                </div>
                                <div className="form-group col-3">
                                    <label className="fw-bold my-2" htmlFor="ENTITY TYPE">ENTITY TYPE</label>
                                    <select className="form-control shadow" name="entity_type" id="" value={values.entity_type} onChange={handleChange}>
                                        <option value="">Select</option>
                                        <option value="1">Client Of Client</option>
                                        <option value="2">Direct Client</option>
                                        <option value="3">Employer</option>
                                        <option value="4">Recruitment Agency</option>
                                        <option value="5">Referral Partner</option>
                                        <option value="6">Staffing/Recruitment Agency</option>
                                        <option value="7">Vendor Partner</option>
                                    </select>
                                    {errors.entity_type && touched.entity_type ? (<p className="text-danger">{errors.entity_type}</p>) : null}
                                </div>
                                <div className="form-group col-3">
                                    <label className="fw-bold my-2" htmlFor="ENTITY STATUS">ENTITY STATUS</label>
                                    <select className="form-control shadow" name="entity_status" id="" value={values.entity_status} onChange={handleChange}>
                                        <option value="">Select</option>
                                        <option value="1">Active</option>
                                        <option value="2">Inactive</option>
                                        <option value="3">Pending</option>
                                    </select>
                                    {errors.entity_status && touched.entity_status ? (<p className="text-danger">{errors.entity_status}</p>) : null}
                                </div>
                                <div className="form-group col-3">
                                    <label className="fw-bold my-2" htmlFor="ACCOUNT MANAGER">ACCOUNT MANAGER</label>
                                    <select className="form-control shadow" name="account_manager" id="" value={values.account_manager} onChange={handleChange}>
                                        <option value="">Select</option>
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <label className="fw-bold my-2" htmlFor="BUSINESS ENTITY TYPE">BUSINESS ENTITY TYPE</label>
                                    <select className="form-control shadow" name="business_entity_id" id="" value={values.business_entity_id} onChange={handleChange}>
                                        <option value="">SELECT</option>
                                        {businessEntity.map((data, key) => {
                                            return (<option value={data['business_entity_id']} key={key} >{data['business_entity_name']}</option>);
                                        })}
                                    </select>
                                    {errors.business_entity_id && touched.business_entity_id ? (<p className="text-danger">{errors.business_entity_id}</p>) : null}
                                </div>
                                <div className="form-group col-3">
                                    <label className="fw-bold my-2" htmlFor="SIGNED CONTRACT">SIGNED CONTRACT</label>
                                    <select className="form-control shadow" name="signed_contract" id="" value={values.signed_contract} onChange={handleChange}>
                                        <option value="">Select</option>
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                        <option value="3">Inprogress</option>
                                        <option value="4">Subscription</option>
                                    </select>
                                    {errors.signed_contract && touched.signed_contract ? (<p className="text-danger">{errors.signed_contract}</p>) : null}
                                </div>
                                <div className="form-group col-3">
                                    <label className="fw-bold my-2" htmlFor="CONTRACT EXPIRE DATE">CONTRACT EXPIRE DATE</label>
                                    <DatePicker dateFormat="dd-MM-yyyy" placeholderText="dd-mm-yyyy" name="contract_expired" className="form-control shadow" selected={datePicker} onChange={(e) => { setDatePicker(e); setFieldValue("contract_expired", format(e, 'dd-MM-yyyy')) }} />
                                </div>
                                <hr className="my-5" />
                                <div className="form-group col-4">
                                    <label className="fw-bold my-2" htmlFor="WEBSITE (http(s)://www.abc.com)">WEBSITE (http(s)://www.abc.com)</label>
                                    <input type="text" name="website" id="" className="form-control shadow" placeholder="" value={values.website} onChange={handleChange} aria-describedby="helpId" />
                                </div>
                                <div className="form-group col-4">
                                    <label className="fw-bold my-2" htmlFor="EMAIL">EMAIL</label>
                                    <input type="email" name="email" id="" className="form-control shadow" placeholder="" value={values.email} onChange={handleChange} aria-describedby="helpId" />
                                    {errors.email && touched.email ? (<p className="text-danger">{errors.email}</p>) : null}
                                </div>
                                <div className="form-group col-4">
                                    <label className="fw-bold my-2" htmlFor="MOBILE">MOBILE</label>
                                    <input type="text" name="mobile" id="" className="form-control shadow" placeholder="" value={values.mobile} onChange={handleChange} aria-describedby="helpId" />
                                    {errors.mobile && touched.mobile ? (<p className="text-danger">{errors.mobile}</p>) : null}
                                </div>
                                <div className="form-group col-4">
                                    <label className="fw-bold my-2" htmlFor="LINKEDIN URL">LINKEDIN URL</label>
                                    <input type="text" name="linkedin_url" id="" className="form-control shadow" placeholder="" value={values.linkedin_url} onChange={handleChange} aria-describedby="helpId" />
                                </div>
                                <div className="form-group col-4">
                                    <label className="fw-bold my-2" htmlFor="FACEBOOK URL">FACEBOOK URL</label>
                                    <input type="text" name="facebook_url" id="" className="form-control shadow" placeholder="" value={values.facebook_url} onChange={handleChange} aria-describedby="helpId" />
                                </div>
                                <div className="form-group col-4">
                                    <label className="fw-bold my-2" htmlFor="TWITER URL">TWITER URL</label>
                                    <input type="text" name="twitter_url" id="" className="form-control shadow" placeholder="" value={values.twitter_url} onChange={handleChange} aria-describedby="helpId" />
                                </div>
                                <hr className="my-5" />
                                <div className="form-group col-6">
                                    <label className="fw-bold my-2" htmlFor="INDUSTRY">INDUSTRY</label>
                                    <select className="form-control shadow" name="industry_id" id="" value={values.industry_id} onChange={handleChange}>
                                        <option value="">SELECT</option>
                                        {industry.map((data, key) => {
                                            return (<option value={data['industry_id']} key={key} >{data['industry_name']}</option>);
                                        })}
                                    </select>
                                    {errors.industry_id && touched.industry_id ? (<p className="text-danger">{errors.industry_id}</p>) : null}
                                </div>
                                <div className="form-group col-6">
                                    <label className="fw-bold my-2" htmlFor="VERTICAL / SEGMENT">VERTICAL / SEGMENT</label>
                                    <select className="form-control shadow" name="vertical_id" id="" value={values.vertical_id} onChange={handleChange}>
                                        <option value="">SELECT</option>
                                        {filterVertical.map((data, key) => {
                                            return (<option value={data['vertical_id']} key={key} >{data['vertical_name']}</option>);
                                        })}
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <label className="fw-bold my-2" htmlFor="DEFAULT GSTIN">DEFAULT GSTIN</label>
                                    <input type="text" name="gstin" id="" className="form-control shadow" placeholder="" value={values.gstin} onChange={handleChange} aria-describedby="helpId" />
                                    {errors.gstin && touched.gstin ? (<p className="text-danger">{errors.gstin}</p>) : null}
                                </div>
                                <div className="form-group col-3">
                                    <label className="fw-bold my-2" htmlFor="CIN">CIN</label>
                                    <input type="text" name="CIN" id="" className="form-control shadow" placeholder="" value={values.CIN} onChange={handleChange} aria-describedby="helpId" />
                                </div>
                                <div className="form-group col-3">
                                    <label className="fw-bold my-2" htmlFor="PAN CARD NUMBER">PAN CARD NUMBER</label>
                                    <input type="text" name="pan_number" id="" className="form-control shadow" placeholder="" value={values.pan_number} onChange={handleChange} aria-describedby="helpId" />
                                </div>
                                <div className="form-group col-3">
                                    <label className="fw-bold my-2" htmlFor="TAN NUMBER">TAN NUMBER</label>
                                    <input type="text" name="tan_number" id="" className="form-control shadow" placeholder="" value={values.tan_number} onChange={handleChange} aria-describedby="helpId" />
                                </div>
                                <hr className="my-5" />
                                <div className="form-group col-6">
                                    <label className="fw-bold my-2" htmlFor="REGISTERED ADDRESS">REGISTERED ADDRESS</label>
                                    <textarea className="form-control shadow" name="registered_address" id="" value={values.registered_address} onChange={handleChange} rows="3"></textarea>
                                </div>
                                <div className="form-group col-6">
                                    <label className="fw-bold my-2" htmlFor="CORPORATE ADDRESS">CORPORATE ADDRESS</label>
                                    <textarea className="form-control shadow" name="corporate_address" id="" value={values.corporate_address} onChange={handleChange} rows="3"></textarea>
                                </div>
                                <div className="form-group col-6">
                                    <label className="fw-bold my-2" htmlFor="COMPANY PROFILE">COMPANY PROFILE</label>
                                    <textarea className="form-control shadow" name="company_profile" id="" value={values.company_profile} onChange={handleChange} rows="3"></textarea>
                                </div>
                                <div className="form-group col-6">
                                    <label className="fw-bold my-2" htmlFor="ADDITIONAL NOTES">ADDITIONAL NOTES</label>
                                    <textarea className="form-control shadow" name="additional_note" id="" value={values.additional_note} onChange={handleChange} rows="3"></textarea>
                                </div>

                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-grid d-md-block text-center text-lg-start my-4 pt-2">
                            {!showHide ? (
                                <button type="button" className="btn btn-primary btn-lg shadow shadow-lg" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={handleSubmit}>Save</button>
                            ) : (<>
                                <button type="button" className="btn btn-danger btn-lg shadow shadow-lg me-2" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={closeModal} >Cancel</button>
                                <button type="button" className="btn btn-warning btn-lg shadow shadow-lg me-2" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={handleSubmit} >Update</button>
                            </>)}
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className="row">
                <h1 className="text-center">Entity List</h1>
                <DataTable
                    className="table table-bordered table-striped"
                    title={<button className="btn btn-sm btn-success me-1" onClick={() => setAddModalShowHide(true)}> Add Entity </button>}
                    columns={columns}
                    data={filterDatas}
                    pagination
                    dense
                    subHeader
                    subHeaderComponent={<input type="text" className="w-25 form-control" placeholder="search" value={search} onChange={(e) => setSearch(e.target.value)} />}
                    persistTableHead
                    selectableRows
                    fixedHeader
                    selectableRowHighlight
                    highlightOnHover
                />

            </div>
        </div>
    )
}