import React from 'react'

export const About = () => {
    return (
        <div className="container-fluid my-5">
            <div className="row">
                <div className="col-sm-12 my-5">
                    <h5 className="bannerSubHeading text-center">About Us</h5>
                    <h1 className="fw-bold my-4 text-center">Create And Enhance Comprehensive Early Childhood Systems</h1>
                </div>

                <div className="col-sm-6">
                    <p className="paragraphStyle">
                        At BULTAMINDS – <span className="fw-bold">Talent Center</span>, we assist your organization with the end to end recruitment process at the best cost with our effective methods, technologies, process and expertise.
                    </p>
                    <p className="paragraphStyle">
                        <span className="fw-bold">Talent Center</span> is being run by a team of Program Managers, Specialists and Talent Sourcing Partners who have rich experience of 32 years in human resources and Talent Acquisition space. Our dedicated talent acquisition team with their experience will work diligently with your team ensuring an open and transparent process and finding the best talent for your organization.
                    </p>

                </div>
                <div className="col-sm-6 text-sm-end">
                    <img src={process.env.PUBLIC_URL + "/assets/webImages/Childhood-Systems-Image.png"} className="img-fluid continueUpDown-animation" alt="" />
                </div>
            </div>
        </div>
    )
}
