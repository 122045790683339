import { useState, useEffect, useContext } from "react";
import { Route, Switch, NavLink, useHistory, BrowserRouter, Link } from 'react-router-dom/cjs/react-router-dom'
import { Dashboard } from './dashboard'
import axios from 'axios'
import Swal from 'sweetalert2'
import { UserAuthenticationDetails } from "../../context/userContext";
import { toast } from 'react-toastify';
import { RoleManagement } from "./roleManagement";
import { UserManagement } from "./userManagement";
import { CandidateManagement } from "./candidateManagement";
import { EntityManagement } from "./entityManagement";
// import { PermissionManagement } from "./permissionManagement";
// import { CreateAdmin } from "./createAdmin";

export const AssociatePage = () => {
    const [logedInUserPermission, setLogedInUserPermission] = useState("")

    const history = useHistory();
    const { user, setUser, token, setToken, axiosURL, bacEndUrl, setLoader } = useContext(UserAuthenticationDetails);
    console.log(user)

    useEffect(() => {
        if (user !== null) {
            history.push('/' + user['permission_path'] + '/dashboard')
            setLogedInUserPermission(user['permission_path'])
        } else {
            history.push('/login');
        }

    }, [user, history])

    const logout = async () => {

        const result = await Swal.fire({
            title: "Are you sure?",
            text: "Do you want to logout",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
        });

        if (result.isConfirmed) {
            axios({
                url: axiosURL,
                method: "POST",
                data: { action: "logout", token: token },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                // console.log(res)
                const message = res.data['msg'];
                if (message === 'success') {
                    sessionStorage.clear();
                    setUser(null);
                    setToken(null);
                    history.push('/login');
                } else {
                    toast.error("Faild to logout, " + res.data['msg'], { autoClose: 5000 })
                }
            }).catch((error) => {
                console.log(error);
            })
        }

    }

    return (
        <BrowserRouter>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-auto px-0">
                        <div id="sidebar" className="collapse collapse-horizontal show border-end vh-100 shadow-sm bg-dark sticky-top">
                            <div id="sidebar-nav">
                                <div className="d-flex flex-column flex-shrink-0 p-3 text-white" style={{ width: "280px", }}>
                                    <Link to="/super-admin" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none bg-p">
                                        <i className="fa fa-user-circle p-2" aria-hidden="true"></i>
                                        <span className="fs-4">{user['entity_ligal_name']}</span>
                                    </Link>
                                    <hr />
                                    <ul className="nav nav-pills flex-column mb-auto text-light sideBarUl">
                                        <li className="nav-item">
                                            <NavLink to={"/" + logedInUserPermission + "/dashboard"} className="nav-link text-light" aria-current="page" activeStyle={{ fontWeight: "bold", backgroundColor: "#0d6efd" }}>
                                                <i className="fa fa-building" aria-hidden="true"></i>
                                                Dashboard
                                            </NavLink>
                                        </li>

                                        {
                                            user['user_type'] === "1" ? (
                                                <>
                                                    {/* SUPER ADMIN MENUS START */}
                                                    <li className="nav-item">
                                                        <button className="btn btn-toggle align-items-center rounded collapsed nav-link text-light" data-bs-toggle="collapse" data-bs-target="#orders-collapse" aria-expanded="true">
                                                            <i className="fa fa-crosshairs" aria-hidden="true"></i>
                                                            User Creation
                                                            <i className="fa fa-angle-down ms-3" aria-hidden="true"></i>
                                                        </button>
                                                        <div className="collapse" id="orders-collapse">
                                                            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                                                {/* <li><NavLink to={"/" + logedInUserPermission + "/permission"} className="nav-link rounded text-light ms-4">Permission Management</NavLink></li> */}
                                                                <li><NavLink to={"/" + logedInUserPermission + "/role"} className="nav-link rounded text-light ms-4">Role Management</NavLink></li>
                                                                <li><NavLink to={"/" + logedInUserPermission + "/entity"} className="nav-link rounded text-light ms-4">Entity Management</NavLink></li>
                                                                <li><NavLink to={"/" + logedInUserPermission + "/user"} className="nav-link rounded text-light ms-4">User Management</NavLink></li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    {/* SUPER ADMIN MENUS END */}
                                                </>
                                            ) : user['user_type'] === "2" ? (<></>) : user['user_type'] === "3" ? (
                                                <>
                                                    <NavLink to={"/" + logedInUserPermission + "/candidate"} className="nav-link text-light" aria-current="page" activeStyle={{ fontWeight: "bold", backgroundColor: "#0d6efd" }}>
                                                        <i className="fa fa-building" aria-hidden="true"></i>
                                                        Candidate Management
                                                    </NavLink>
                                                </>
                                            ) : (<></>)
                                        }

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col ps-md-2">
                        <div className="row sticky-top bg-dark py-3">
                            <div className="d-flex justify-content-between">
                                <span data-bs-target="#sidebar" data-bs-toggle="collapse" className="p-2 text-decoration-none text-light">
                                    <i className="fa fa-bars fa-xl" aria-hidden="true"></i>
                                </span>
                                <span>
                                    <span className="text-light fw-bolder me-3">{user['first_name'] + " " + user['last_name'] + " ( " + user['permission_name'] + " ) "}</span>
                                    <button className="btn btn-sm btn-danger rounded p-2" onClick={logout}>
                                        <i className="fa fa-power-off" aria-hidden="true"></i>Logout
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <Switch>
                                <Route exact path={"/" + logedInUserPermission + "/dashboard"} >
                                    <Dashboard axiosURL={axiosURL} />
                                </Route>
                                {/* <Route exact path={"/" + logedInUserPermission + "/permission"} >
                                    <PermissionManagement axiosURL={axiosURL} />
                                </Route> */}
                                <Route exact path={"/" + logedInUserPermission + "/role"} >
                                    <RoleManagement axiosURL={axiosURL} />
                                </Route>
                                <Route exact path={"/" + logedInUserPermission + "/entity"} >
                                    <EntityManagement axiosURL={axiosURL} />
                                </Route>
                                <Route exact path={"/" + logedInUserPermission + "/user"} >
                                    <UserManagement axiosURL={axiosURL} />
                                </Route>
                                <Route exact path={"/" + logedInUserPermission + "/candidate"} >
                                    <CandidateManagement axiosURL={axiosURL} bacEndUrl={bacEndUrl} setLoader={setLoader}/>
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </BrowserRouter>
    )
}