export const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <div className="container-fluid p-5" style={{ "backgroundColor": "#353535" }}>
                    <div className="row text-center m">
                        <div className="col-md-3  text-start">
                            <img src={process.env.PUBLIC_URL + "/assets/webImages/Logo/Original With white text.png"} alt="" className="my-3 img-fluid" />
                        </div>
                        <div className="col-md-3 fadeLeftIn-animation my-3">
                            <p className="paragraphStyle text-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                        </div>
                        <div className="col-md-2 fadeZoomIn-animation my-3">
                            <ul className="list-unstyled paragraphStyle text-center text-light">
                                <li>Home</li>
                                <li>About Us</li>
                                <li>Our Services</li>
                                <li>Referral</li>
                            </ul>
                        </div>
                        <div className="col-md-2 fadeZoomIn-animation my-3">
                            <ul className="list-unstyled paragraphStyle text-center text-light">
                                <li>Jobs</li>
                                <li>Blogs</li>
                                <li>Contact Us</li>
                                <li>Expert</li>
                            </ul>
                        </div>
                        <div className="col-md-2 fadeRightIn-animation my-3">
                            <ul className="list-unstyled paragraphStyle text-center text-light">
                                <li>Interview</li>
                                <li>Remodeling</li>
                                <li>Aero Space</li>
                                <li>Roofing</li>
                            </ul>
                        </div>
                    </div>
            </div>
            <div className="container-fluid bg-dark text-light py-2">
                <div className="row">
                    <div className="col-6 text-start">© 2023 Talent Center - All Rights Reserved.</div>
                    <div className="col-6 text-end">Privacy Policy</div>
                </div>
            </div>
            <button className="scrollup" onClick={scrollToTop} >
                <img src={process.env.PUBLIC_URL + "/assets/webImages/arrowUp.png"} alt="" width="20" height="20" />
            </button>

        </>
    )
}