import { useState, useEffect } from 'react'
import axios from "axios";
import { toast } from 'react-toastify';
import DataTable from "react-data-table-component";
// import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from "formik";
import { candidateExperienceSchema, candidateFresherSchema, candidateExperienceEditSchema, candidateFresherEditSchema } from '../../schemas';

export const CandidateManagement = (props) => {

    const initialValues = {
        action: "createCandidate",
        can_id: "",
        user_id: JSON.parse(sessionStorage.getItem("user"))['user_id'],
        first_name: "",
        last_name: "",
        dob: "",
        gender: "",
        mobile: "",
        alt_mobile: "",
        email: "",
        alt_email: "",
        pan_number: "",
        adhar_number: "",
        marital_status: "",
        sourc_id: JSON.parse(sessionStorage.getItem("user"))['entity_ligal_name'],
        country_id: "",
        state_id: "",
        city_id: "",
        pin_code: "",
        nationality: "",
        language_known: "",
        address: "",
        experience_type: "",
        work_experience_in_year: 0,
        work_experience_in_month: 0,
        designation_name: "",
        current_company_name: "",
        remote_status: "",
        job_type: "",
        notice_period: 0,
        vertical_id: "",
        ctc_type: "",
        current_ctc_lakhs: 0,
        current_ctc_thausands: 0,
        skills: "",
        preferred_location: "",
        upload_file: "",
    }

    const [userExperienceType, setUserExperienceType] = useState('fresher');
    const [action, setAction] = useState("createCandidate");
    const [validationType, setValidationType] = useState(candidateFresherSchema);
    const [runUseEffect, setRunUseEffect] = useState(false);
    const [showHide, setShowHide] = useState(false);
    const [candidateData, setCandidateData] = useState([]);
    const [filterDatas, setFilterDatas] = useState([]);
    const [search, setSearch] = useState("");
    const [countryMaster, setCountryMaster] = useState([]);
    const [stateMaster, setStateMaster] = useState([]);
    const [cityMaster, setCityMaster] = useState([]);
    const [vertical, setVertical] = useState([])
    const [datePicker, setDatePicker] = useState(null);
    const [modalShowHide, setModalShowHide] = useState(false);
    const [addModalShowHide, setAddModalShowHide] = useState(false);
    const [filterCandidateForModal, setFilterCandidateForModal] = useState(false);
    const [resumeUrl, setResumeUrl] = useState("");
    const [searchParam] = useState(["can_code", "first_name", "last_name", "email", "mobile"]);

    const { values, errors, touched, setFieldValue, handleChange, handleSubmit, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: validationType,
        onSubmit: (formData) => {
            formData.action = action;
            formData.experience_type = userExperienceType;
            formData.dob = format(datePicker, 'dd-MM-yyyy');
            // console.log(formData)
            props.setLoader(true);
            axios({
                url: props.axiosURL,
                method: "POST",
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                props.setLoader(false);
                // console.log(res.data);
                const status = res.data['status'];
                if (status === 1) {
                    if (action === "createCandidate") {
                        toast.success("Data Inserted Successfully", { autoClose: 3000 })
                    }else{
                        toast.success("Data Updated Successfully", { autoClose: 3000 })
                    }                    
                    setRunUseEffect(!runUseEffect);
                    setAddModalShowHide(false);
                    closeModalFun();
                    resetForm();
                } else {
                    toast.error(res.data['msg'], { autoClose: 3000 })
                }
            }).catch((error) => {
                console.log(error);
            })
        }
    });


    const closeModalFun = () => {
        setDatePicker(null);
        setShowHide(false);
        setAddModalShowHide(false);
        setResumeUrl("");
        resetForm();
        setAction("createCandidate");
        setValidationType(candidateFresherSchema);
        setFieldValue("country_id", 101)
    }

    useEffect(() => {
        // get all country
        axios({
            url: props.axiosURL,
            method: "POST",
            data: { action: "viewAllCountries" },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            console.log(res.data);
            const roleCount = res.data['count'];
            if (roleCount > 0) {
                setCountryMaster(res.data['data'])
                setFieldValue("country_id", 101)
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [props.axiosURL, setFieldValue])

    useEffect(() => {
        // get all state
        axios({
            url: props.axiosURL,
            method: "POST",
            data: { country_id: values.country_id, action: "viewStatesCountryIdWise" },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            // console.log(res.data);
            const roleCount = res.data['count'];
            if (roleCount > 0) {
                setStateMaster(res.data['data'])
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [props.axiosURL, values.country_id])

    useEffect(() => {
        // get all city
        axios({
            url: props.axiosURL,
            method: "POST",
            data: { state_id: values.state_id, action: "viewCityStateIdWise" },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            // console.log(res.data);
            const roleCount = res.data['count'];
            if (roleCount > 0) {
                setCityMaster(res.data['data'])
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [props.axiosURL, values.state_id])


    useEffect(() => {
        // get vertical details
        axios({
            url: props.axiosURL,
            method: "POST",
            data: { action: "viewAllVerticals" },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            // console.log(res.data);
            const roleCount = res.data['count'];
            if (roleCount > 0) {
                setVertical(res.data['data'])
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [props.axiosURL])


    useEffect(() => {
        axios({
            url: props.axiosURL,
            method: "POST",
            data: { user_id: JSON.parse(sessionStorage.getItem("user"))['user_id'], action: "getCandidateAgencyUserWise" },
            headers: {
                'Content-type': 'multipart/form-data'
            }
        }).then((res) => {
            // console.log(res.data);
            const roleCount = res.data['count'];
            if (roleCount > 0) {
                setCandidateData(res.data['data'])
                setFilterDatas(res.data['data'])
            }
        }).catch((error) => {
            console.log(error);
        })

    }, [props.axiosURL, runUseEffect])


    const viewDataOnModal = (can_code) => {
        setModalShowHide(true)
        setFilterCandidateForModal(candidateData.filter((value) => {
            return value.can_code === can_code;
        })[0])
    }

    const convertDateFormat = (inputDate) => {
        // console.log(inputDate)
        if (inputDate !== null) {
            var parts = inputDate.split(' ')[0].split('-');
            // var formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
            return new Date(new Date(parts[0], parts[1], parts[2]));
        } else {
            return new Date()
        }
    }

    const fetchFotEdit = (can_code) => {
        setAction("updateCandidate")
        setValidationType(candidateFresherEditSchema);
        setShowHide(true);
        setAddModalShowHide(true)
        const filterCandidateDetails = candidateData.filter((value) => {
            return value.can_code === can_code;
        })[0]
        // console.log(filterCandidateDetails);
        setResumeUrl("https://ats.bultaminds.com/data/resumes/" + filterCandidateDetails.resume_attachment);
        setDatePicker(convertDateFormat(filterCandidateDetails.dob));

        setFieldValue("action", "updateCandidate")
        setFieldValue("user_id", JSON.parse(sessionStorage.getItem("user"))['user_id'])
        setFieldValue("can_code", filterCandidateDetails.can_code)
        setFieldValue("first_name", filterCandidateDetails.first_name)
        setFieldValue("last_name", filterCandidateDetails.last_name)
        setFieldValue("dob", filterCandidateDetails.dob)
        setFieldValue("gender", filterCandidateDetails.gender)
        setFieldValue("mobile", filterCandidateDetails.mobile)
        setFieldValue("alt_mobile", filterCandidateDetails.alt_mobile)
        setFieldValue("email", filterCandidateDetails.email)
        setFieldValue("alt_email", filterCandidateDetails.alt_email)
        setFieldValue("pan_number", filterCandidateDetails.pan_card)
        setFieldValue("adhar_number", filterCandidateDetails.aadhar)
        setFieldValue("marital_status", filterCandidateDetails.martial_status)
        setFieldValue("sourc_id", JSON.parse(sessionStorage.getItem("user"))['entity_ligal_name'])
        setFieldValue("country_id", filterCandidateDetails.country)
        setFieldValue("state_id", filterCandidateDetails.state)
        setFieldValue("city_id", filterCandidateDetails.city)
        setFieldValue("pin_code", filterCandidateDetails.pincode)
        setFieldValue("nationality", filterCandidateDetails.nationality)
        setFieldValue("language_known", filterCandidateDetails.languages)
        setFieldValue("address", filterCandidateDetails.address)
        setUserExperienceType(filterCandidateDetails.experience_type)
        setFieldValue("experience_type", filterCandidateDetails.experience_type)
        setFieldValue("work_experience_in_year", filterCandidateDetails.years_work_exp)
        setFieldValue("work_experience_in_month", filterCandidateDetails.months_work_exp)
        setFieldValue("designation_name", filterCandidateDetails.designation)
        setFieldValue("current_company_name", filterCandidateDetails.current_company_name)
        setFieldValue("remote_status", filterCandidateDetails.remote_status)
        setFieldValue("job_type", filterCandidateDetails.job_type)
        setFieldValue("notice_period", filterCandidateDetails.notice_period)
        setFieldValue("vertical_id", filterCandidateDetails.vertical)
        setFieldValue("ctc_type", filterCandidateDetails.ctc_type)
        setFieldValue("current_ctc_lakhs", filterCandidateDetails.current_ctc_Lakhs)
        setFieldValue("current_ctc_thausands", filterCandidateDetails.current_ctc_Thousands)
        setFieldValue("skills", filterCandidateDetails.skills)
        setFieldValue("preferred_location", filterCandidateDetails.preferred_location)
        setFieldValue("old_file", filterCandidateDetails.resume_attachment)
        setFieldValue("can_id", filterCandidateDetails.can_id)
    }


    const columns = [
        {
            name: "Id",
            selector: (row) => row.can_code,
            sortable: true,
            wrap: true,
        },
        {
            name: "Status",
            selector: (row) => row.can_status,
            sortable: true,
            wrap: true,
        },
        {
            name: "Name",
            selector: (row) => row.first_name + " " + row.last_name,
            sortable: true,
            wrap: true,
        },
        {
            name: "Mobile",
            selector: (row) => row.mobile,
            sortable: true,
            wrap: true,
        },
        {
            name: "Designation",
            selector: (row) => row.designation,
            sortable: true,
            wrap: true,
        },
        {
            name: "Resume",
            selector: (row) => <button className="btn btn-sm btn-primarry shadow" onClick={window.open("https://ats.bultaminds.com/data/resumes/" + row.resume_attachment, '_blank')}>view document</button>,
            sortable: true,
            wrap: true,
        },
        {
            name: "Created On",
            selector: (row) => row.created_on,
            sortable: true,
            wrap: true,
        },
        {
            name: "Action",
            grow: 2,
            cell: (row) => (
                <>
                    <button className="btn btn-sm btn-success me-1" onClick={() => viewDataOnModal(row.can_code)}>
                        <i className="fa fa-eye" aria-hidden="true"></i>
                    </button>
                    <button className="btn btn-sm btn-warning me-1" onClick={() => fetchFotEdit(row.can_code)}>
                        <i className="fa fa-pencil-square" aria-hidden="true"></i>
                    </button>
                </>
            ),
        },
    ]

    useEffect(() => {
        const result = candidateData.filter(item => {
            return searchParam.some(newItem => {
                return (item[newItem].toString().toLowerCase().indexOf(search.toLowerCase()) > -1);
            });
        });
        setFilterDatas(result);
    }, [candidateData, search, searchParam]);

    const DateConverter = (dateString) => {
        var date = new Date(dateString);
        var options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }


    return (
        <div className='container-fluid'>
            <Modal show={modalShowHide} size="xl" onHide={() => setModalShowHide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>View Candidate Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row p-4'>
                        <div className='col-sm-6'>
                            <div><span><span className='fw-bolder'>FIRST NAME : </span>{filterCandidateForModal.first_name}</span></div>
                            <div><span><span className='fw-bolder'>LAST NAME</span>{filterCandidateForModal.last_name}</span></div>
                            <div><span><span className='fw-bolder'>DOB : </span>{DateConverter(filterCandidateForModal.dob)}</span></div>
                            <div><span><span className='fw-bolder'>GENDER : </span>{filterCandidateForModal.gender}</span></div>
                            <div><span><span className='fw-bolder'>MOBILE : </span>{filterCandidateForModal.mobile}</span></div>
                            <div><span><span className='fw-bolder'>ALTERNATE MOBILE : </span>{filterCandidateForModal.alt_mobile}</span></div>
                            <div><span><span className='fw-bolder'>EMAIL : </span>{filterCandidateForModal.email}</span></div>
                            <div><span><span className='fw-bolder'>ALTERNATE EMAIL : </span>{filterCandidateForModal.alt_email}</span></div>
                            <div><span><span className='fw-bolder'>PAN CARD NUMBER : </span>{filterCandidateForModal.pan_card}</span></div>
                            <div><span><span className='fw-bolder'>ADHAR CARD NUMBER : </span>{filterCandidateForModal.aadhar}</span></div>
                            <div><span><span className='fw-bolder'>MARITAL STATUS : </span>{filterCandidateForModal.martial_status}</span></div>
                            <div><span><span className='fw-bolder'>CANDIDATE STATUS : </span>{filterCandidateForModal.can_status}</span></div>
                            <div><span><span className='fw-bolder'>SOURCE : </span>{filterCandidateForModal.source}</span></div>
                            <div><span><span className='fw-bolder'>COUNTRY : </span>{filterCandidateForModal.country_name}</span></div>
                            <div><span><span className='fw-bolder'>STATE : </span>{filterCandidateForModal.state_name}</span></div>
                            <div><span><span className='fw-bolder'>CITY : </span>{filterCandidateForModal.city_name}</span></div>
                            <div><span><span className='fw-bolder'>PIN CODE : </span>{filterCandidateForModal.pincode}</span></div>
                            <div><span><span className='fw-bolder'>NATIONALITY : </span>{filterCandidateForModal.nationality}</span></div>
                            <div><span><span className='fw-bolder'>COMPANY PROFILE : </span>{filterCandidateForModal.company_profile}</span></div>
                            <div><span><span className='fw-bolder'>LANGUAGE KNOWN : </span>{filterCandidateForModal.languages}</span></div>
                            <div><span><span className='fw-bolder'>ADDRESS : </span>{filterCandidateForModal.address}</span></div>
                        </div>
                        <div className='col-sm-6'>
                            <div><span><span className='fw-bolder'>WORK EXPERIENCE : </span>{filterCandidateForModal.years_work_exp} YEAR, {filterCandidateForModal.months_work_exp} MONTH</span></div>
                            <div><span><span className='fw-bolder'>DESIGNATION : </span>{filterCandidateForModal.designation}</span></div>
                            <div><span><span className='fw-bolder'>REMOTE STATUS : </span>{filterCandidateForModal.remote_status}</span></div>
                            <div><span><span className='fw-bolder'>REMOTE : </span>{filterCandidateForModal.remote_status}</span></div>
                            <div><span><span className='fw-bolder'>JOB TYPE : </span>{filterCandidateForModal.job_type}</span></div>
                            <div><span><span className='fw-bolder'>NOTICE PERIOD : </span>{filterCandidateForModal.notice_period} Days</span></div>
                            <div><span><span className='fw-bolder'>VERTICAL / SEGMENT : </span>{filterCandidateForModal.vertical_name}</span></div>
                            <div><span><span className='fw-bolder'>CTC TYPE: </span>{filterCandidateForModal.ctc_type}</span></div>
                            <div><span><span className='fw-bolder'>CURRENT CTC : </span>{filterCandidateForModal.current_ctc_Lakhs} Lakhs, {filterCandidateForModal.current_ctc_Thousands} Thousands </span></div>
                            <div><span><span className='fw-bolder'>SKILLS : </span>{filterCandidateForModal.skills}</span></div>
                            <div><span><span className='fw-bolder'>PREFERRED LOCATION : </span>{filterCandidateForModal.preferred_location}</span></div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-warning btn-lg shadow shadow-lg me-2" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={() => setModalShowHide(false)} >Close</button>
                </Modal.Footer>
            </Modal>
            <Modal show={addModalShowHide} size="xl" onHide={closeModalFun}>
                <Modal.Header closeButton>
                    <h1 className="text-center my-3">ADD CANDIDATE DETAILS</h1>
                </Modal.Header>
                <form method="POST">
                    <Modal.Body>
                        <div className="row p-5">
                            <div className='col-xl-12'>

                                <div className="form-outline mb-4 row">
                                    <div className="form-group col-xl-3">
                                        <label htmlFor="FIRST NAME">FIRST NAME <span className='text-danger fw-bolder'>*</span></label>
                                        <input type="text" className="form-control shadow" name="first_name" id="" value={values.first_name} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                        {errors.first_name && touched.first_name ? (<p className="text-danger">{errors.first_name}</p>) : null}
                                    </div>
                                    <div className="form-group col-xl-3">
                                        <label htmlFor="LAST NAME">LAST NAME</label>
                                        <input type="text" className="form-control shadow" name="last_name" id="" value={values.last_name} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                    </div>
                                    <div className="form-group col-xl-3">
                                        <label htmlFor="DATE OF BIRTH">DATE OF BIRTH <span className='text-danger fw-bolder'>*</span></label><br />
                                        <DatePicker dateFormat="dd-MM-yyyy" name='dob' className="form-control shadow" selected={datePicker} onChange={(date) => { setDatePicker(date); setFieldValue("dob", date) }} placeholderText="dd-mm-yyyy" />
                                        {errors.dob && touched.dob ? (<p className="text-danger">{errors.dob}</p>) : null}
                                    </div>
                                    <div className="form-group col-xl-3">
                                        <label htmlFor="GENDER">GENDER <span className='text-danger fw-bolder'>*</span></label>
                                        <select className="form-control shadow" name="gender" id="" value={values.gender} onChange={handleChange}>
                                            <option value="0">Select</option>
                                            <option value="Female">Female</option>
                                            <option value="Male">Male</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Transgender">Transgender</option>
                                        </select>
                                        {errors.gender && touched.gender ? (<p className="text-danger">{errors.gender}</p>) : null}
                                    </div>

                                    <div className="form-group col-xl-3">
                                        <label htmlFor="MOBILE NO.">MOBILE NO. <span className='text-danger fw-bolder'>*</span></label>
                                        <input type="text" className="form-control shadow" name="mobile" id="" value={values.mobile} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                        {errors.mobile && touched.mobile ? (<p className="text-danger">{errors.mobile}</p>) : null}
                                    </div>
                                    <div className="form-group col-xl-3">
                                        <label htmlFor="WHATSAPP NO.">WHATSAPP NO.</label>
                                        <input type="text" className="form-control shadow" name="alt_mobile" id="" value={values.alt_mobile} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                    </div>
                                    <div className="form-group col-xl-3">
                                        <label htmlFor="EMAIL ID">EMAIL ID <span className='text-danger fw-bolder'>*</span></label>
                                        <input type="text" className="form-control shadow" name="email" id="" value={values.email} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                        {errors.email && touched.email ? (<p className="text-danger">{errors.email}</p>) : null}
                                    </div>
                                    <div className="form-group col-xl-3">
                                        <label htmlFor="ALTERNATE EMAIL ID">ALTERNATE EMAIL ID</label>
                                        <input type="text" className="form-control shadow" name="alt_email" id="" value={values.alt_email} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                    </div>
                                    <div className="form-group col-xl-3">
                                        <label htmlFor="PAN CARD NUMBER">PAN CARD NUMBER</label>
                                        <input type="text" name="pan_number" id="" className="form-control shadow" placeholder="" value={values.pan_number} onChange={handleChange} aria-describedby="helpId" />
                                    </div>
                                    <div className="form-group col-xl-3">
                                        <label htmlFor="ADHAR CARD NYMBER">ADHAR CARD NUMBER</label>
                                        <input type="text" className="form-control shadow" name="adhar_number" id="" value={values.adhar_number} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                    </div>
                                    <div className="form-group col-xl-3">
                                        <label htmlFor="MARITAL STATUS">MARITAL STATUS</label>
                                        <select className="form-control shadow" name="marital_status" id="" value={values.marital_status} onChange={handleChange}>
                                            <option value="0">Select</option>
                                            <option value="Divorced">Divorced</option>
                                            <option value="Married">Married</option>
                                            <option value="Single">Single</option>
                                            <option value="Widowed">Widowed</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-xl-3">
                                        <label htmlFor="COUNTRY">COUNTRY <span className='text-danger fw-bolder'>*</span></label>
                                        <select className="form-control shadow" name="country_id" id="" value={values.country_id} onChange={handleChange}>
                                            <option value="0">Select</option>
                                            {countryMaster.map((countryData, key) => {
                                                return (<option value={countryData.country_id} key={key}> {countryData.country} </option>)
                                            })}
                                        </select>
                                        {errors.country_id && touched.country_id ? (<p className="text-danger">{errors.country_id}</p>) : null}
                                    </div>
                                    <div className="form-group col-xl-4">
                                        <label htmlFor="STATE">STATE <span className='text-danger fw-bolder'>*</span></label>
                                        <select className="form-control shadow" name="state_id" id="" value={values.state_id} onChange={handleChange}>
                                            <option value="0">Select</option>
                                            {stateMaster.map((stateData, key) => {
                                                return (<option value={stateData.state_id} key={key}> {stateData.state} </option>)
                                            })}
                                        </select>
                                        {errors.state_id && touched.state_id ? (<p className="text-danger">{errors.state_id}</p>) : null}
                                    </div>
                                    <div className="form-group col-xl-4">
                                        <label htmlFor="CITY">CITY <span className='text-danger fw-bolder'>*</span></label>
                                        <select className="form-control shadow" name="city_id" id="" value={values.city_id} onChange={handleChange}>
                                            <option value="0">Select</option>
                                            {cityMaster.map((cityData, key) => {
                                                return (<option value={cityData.city_id} key={key}> {cityData.city} </option>)
                                            })}
                                        </select>
                                        {errors.city_id && touched.city_id ? (<p className="text-danger">{errors.city_id}</p>) : null}
                                    </div>
                                    <div className="form-group col-xl-4">
                                        <label htmlFor="PIN CODE">PIN CODE</label>
                                        <input type="text" className="form-control shadow" name="pin_code" id="" value={values.pin_code} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                    </div>
                                    <div className="form-group col-xl-4">
                                        <label htmlFor="NATIONALITY">NATIONALITY</label>
                                        <input type="text" className="form-control shadow" name="nationality" id="" value={values.nationality} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                    </div>
                                    <div className="form-group col-xl-4">
                                        <label htmlFor="LANGUAGE KNOWN">LANGUAGE KNOWN</label>
                                        <input type="text" className="form-control shadow" name="language_known" id="" value={values.language_known} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                    </div>
                                    <div className="form-group col-4">
                                        <label htmlFor="ADDRESS">ADDRESS</label>
                                        <textarea className="form-control shadow" name="address" id="" value={values.address} onChange={handleChange} rows="1"></textarea>
                                    </div>


                                    <hr className='my-5' />

                                    <h3 className='my-3'>CANDIDATE WORKS DETAILS</h3>



                                    <div className="form-group col-sm-12 my-3">
                                        <label className='fw-bold my-2' htmlFor="GENDER">Experience Type</label><br />
                                        <div className="form-check form-check-inline">
                                            <input type="radio" name="experience_type" id="experience_type" className="form-check-input" value="fresher" checked={userExperienceType === 'fresher'} onChange={(e) => { setUserExperienceType(e.target.value); action === "createCandidate" ? setValidationType(candidateFresherSchema) : setValidationType(candidateFresherEditSchema) }} />
                                            <label className="form-check-label" htmlFor="">Fresher</label><br />
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" name="experienceType" id="experience_type" className="form-check-input" value="experienced" checked={userExperienceType === "experienced"} onChange={(e) => { setUserExperienceType(e.target.value); action === "createCandidate" ? setValidationType(candidateExperienceSchema) : setValidationType(candidateExperienceEditSchema) }} />
                                            <label className="form-check-label" htmlFor="">Experience</label>
                                        </div>
                                        {errors.experience_type && touched.experience_type ? (<p className="text-danger">{errors.experience_type}</p>) : null}

                                    </div>



                                    {userExperienceType === 'experienced' ? (
                                        <>
                                            <div className="form-group col-xl-3">
                                                <label htmlFor="REMOTE STATUS">REMOTE STATUS <span className='text-danger fw-bolder'>*</span></label>
                                                <select className="form-control shadow" name="remote_status" id="" value={values.remote_status} onChange={handleChange}>
                                                    <option value="0">Select</option>
                                                    <option value="Hybrid">Hybrid</option>
                                                    <option value="Work From Home">Work From Home</option>
                                                    <option value="Work From Office">Work From Office</option>
                                                </select>
                                                {errors.remote_status && touched.remote_status ? (<p className="text-danger">{errors.remote_status}</p>) : null}
                                            </div>

                                            <div className="form-group col-xl-6">
                                                <div className="row">
                                                    <label htmlFor="WORK EXPERIENCE">WORK EXPERIENCE <span className='text-danger fw-bolder'>*</span></label>
                                                    <div className="col-6">
                                                        <input type="number" className="form-control shadow" name="work_experience_in_year" id="" value={values.work_experience_in_year} onChange={handleChange} aria-describedby="helpId" placeholder="Work Experience in Year" />
                                                        {errors.work_experience_in_year && touched.work_experience_in_year ? (<p className="text-danger">{errors.work_experience_in_year}</p>) : null}
                                                    </div>
                                                    <div className="col-6">
                                                        <input type="number" className="form-control shadow" name="work_experience_in_month" id="" value={values.work_experience_in_month} onChange={handleChange} aria-describedby="helpId" placeholder="Work Experience in Month" />
                                                        {errors.work_experience_in_month && touched.work_experience_in_month ? (<p className="text-danger">{errors.work_experience_in_month}</p>) : null}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="form-group col-xl-3">
                                                <label htmlFor="DESIGNATION">DESIGNATION <span className='text-danger fw-bolder'>*</span></label>
                                                <input type="text" className="form-control shadow" name="designation_name" id="" value={values.designation_name} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                                {errors.designation_name && touched.designation_name ? (<p className="text-danger">{errors.designation_name}</p>) : null}
                                            </div>
                                            <div className="form-group col-xl-3">
                                                <label htmlFor="CURRENT COMPANY NAME">CURRENT COMPANY NAME <span className='text-danger fw-bolder'>*</span></label>
                                                <input type="text" className="form-control shadow" name="current_company_name" id="" value={values.current_company_name} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                                {errors.current_company_name && touched.current_company_name ? (<p className="text-danger">{errors.current_company_name}</p>) : null}
                                            </div>
                                            <div className="form-group col-xl-3">
                                                <label htmlFor="JOB TYPE">JOB TYPE <span className='text-danger fw-bolder'>*</span></label>
                                                <select className="form-control shadow" name="job_type" id="" value={values.job_type} onChange={handleChange}>
                                                    <option value="0">Select</option>
                                                    <option value="">Select</option>
                                                    <option value="Appretinceship">Appretinceship</option>
                                                    <option value="Consultant">Consultant</option>
                                                    <option value="Contractual">Contractual</option>
                                                    <option value="Freelance">Freelance</option>
                                                    <option value="Full Time">Full Time</option>
                                                    <option value="Internship">Internship</option>
                                                    <option value="Part Time">Part Time</option>
                                                    <option value="Seasonal">Seasonal</option>
                                                </select>
                                                {errors.job_type && touched.job_type ? (<p className="text-danger">{errors.job_type}</p>) : null}
                                            </div>
                                            <div className="form-group col-xl-3">
                                                <label htmlFor="NOTICE PERIOD (IN DAYS)">NOTICE PERIOD (IN DAYS) <span className='text-danger fw-bolder'>*</span></label>
                                                <input type="number" className="form-control shadow" name="notice_period" id="" value={values.notice_period} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                                {errors.notice_period && touched.notice_period ? (<p className="text-danger">{errors.notice_period}</p>) : null}
                                            </div>
                                            <div className="form-group col-xl-3">
                                                <label htmlFor="VERTICAL / SEGMENT">VERTICAL / SEGMENT <span className='text-danger fw-bolder'>*</span></label>
                                                <select className="form-control shadow" name="vertical_id" id="" value={values.vertical_id} onChange={handleChange}>
                                                    <option value="0">Select</option>
                                                    {vertical.map((data, key) => {
                                                        return (<option value={data['vertical_id']} key={key} >{data['vertical_name']}</option>);
                                                    })}
                                                </select>
                                                {errors.vertical_id && touched.vertical_id ? (<p className="text-danger">{errors.vertical_id}</p>) : null}
                                            </div>
                                            <div className="form-group col-xl-3">
                                                <label htmlFor="CTC TYPE">CTC TYPE <span className='text-danger fw-bolder'>*</span></label>
                                                <select className="form-control shadow" name="ctc_type" id="" value={values.ctc_type} onChange={handleChange}>
                                                    <option value="0">Select</option>
                                                    <option value="Annual">Annual</option>
                                                    <option value="Monthly">Monthly</option>
                                                </select>
                                                {errors.ctc_type && touched.ctc_type ? (<p className="text-danger">{errors.ctc_type}</p>) : null}
                                            </div>
                                            <div className="form-group col-xl-9">
                                                <div className="row">
                                                    <label htmlFor="CURRENT CTC">CURRENT CTC <span className='text-danger fw-bolder'>*</span></label>
                                                    <div className="col-sm-4">
                                                        <input type="number" className="form-control shadow" name="current_ctc_lakhs" id="" value={values.current_ctc_lakhs} onChange={handleChange} aria-describedby="helpId" placeholder="Current CTC in Lakhs" />
                                                        {errors.current_ctc_lakhs && touched.current_ctc_lakhs ? (<p className="text-danger">{errors.current_ctc_lakhs}</p>) : null}
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <input type="number" className="form-control shadow" name="current_ctc_thausands" id="" value={values.current_ctc_thausands} onChange={handleChange} aria-describedby="helpId" placeholder="Current CTC in Thausands" />
                                                        {errors.current_ctc_thausands && touched.current_ctc_thausands ? (<p className="text-danger">{errors.current_ctc_thausands}</p>) : null}
                                                    </div>
                                                    <div className="col-sm-4">
                                                        {values.current_ctc_lakhs} Lakhs, {values.current_ctc_thausands} Thousands
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="form-group col-xl-6">
                                                <label htmlFor="SKILLS">SKILLS <span className='text-danger fw-bolder'>*</span></label>
                                                <textarea className="form-control shadow" name="skills" id="" value={values.skills} onChange={handleChange} rows="1"></textarea>
                                                {errors.skills && touched.skills ? (<p className="text-danger">{errors.skills}</p>) : null}
                                            </div>
                                            <div className="form-group col-xl-6">
                                                <label htmlFor="PREFERRED LOCATION">PREFERRED LOCATION <span className='text-danger fw-bolder'>*</span></label>
                                                <textarea className="form-control shadow" name="preferred_location" id="" value={values.preferred_location} onChange={handleChange} rows="1"></textarea>
                                                {errors.preferred_location && touched.preferred_location ? (<p className="text-danger">{errors.preferred_location}</p>) : null}
                                            </div>
                                            <div className="form-group col-12">
                                                <label htmlFor="UPLOAD RESUMES">UPLOAD RESUMES <span className='text-danger fw-bolder'>*</span> (Only pdf, doc, docx file allow)</label>
                                                <input type="file" className="form-control shadow" name="upload_file" id="" onChange={(e) => setFieldValue("upload_file", e.currentTarget.files[0])} aria-describedby="helpId" placeholder="" /><br />
                                                {resumeUrl !== "" ? <button className="btn btn-sm btn-primarry shadow" onClick={window.open(resumeUrl, '_blank')}>view current resume</button> : <></>}
                                                {errors.upload_file && touched.upload_file ? (<p className="text-danger">{errors.upload_file}</p>) : null}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {/* <div className="form-group col-xl-3">
                                                <label htmlFor="NOTICE PERIOD (IN DAYS)">NOTICE PERIOD (IN DAYS) <span className='text-danger fw-bolder'>*</span></label>
                                                <input type="number" className="form-control shadow" name="notice_period" id="" value={values.notice_period} onChange={handleChange} aria-describedby="helpId" placeholder="" />
                                                {errors.notice_period && touched.notice_period ? (<p className="text-danger">{errors.notice_period}</p>) : null}
                                            </div> */}
                                            <div className="form-group col-xl-6">
                                                <label htmlFor="SKILLS">SKILLS <span className='text-danger fw-bolder'>*</span></label>
                                                <textarea className="form-control shadow" name="skills" id="" value={values.skills} onChange={handleChange} rows="1"></textarea>
                                                {errors.skills && touched.skills ? (<p className="text-danger">{errors.skills}</p>) : null}
                                            </div>
                                            <div className="form-group col-xl-6">
                                                <label htmlFor="PREFERRED LOCATION">PREFERRED LOCATION <span className='text-danger fw-bolder'>*</span></label>
                                                <textarea className="form-control shadow" name="preferred_location" id="" value={values.preferred_location} onChange={handleChange} rows="1"></textarea>
                                                {errors.preferred_location && touched.preferred_location ? (<p className="text-danger">{errors.preferred_location}</p>) : null}
                                            </div>
                                            <div className="form-group col-xl-6">
                                                <label htmlFor="REMOTE STATUS">REMOTE STATUS <span className='text-danger fw-bolder'>*</span></label>
                                                <select className="form-control shadow" name="remote_status" id="" value={values.remote_status} onChange={handleChange}>
                                                    <option value="0">Select</option>
                                                    <option value="Hybrid">Hybrid</option>
                                                    <option value="Work From Home">Work From Home</option>
                                                    <option value="Work From Office">Work From Office</option>
                                                </select>
                                                {errors.remote_status && touched.remote_status ? (<p className="text-danger">{errors.remote_status}</p>) : null}
                                            </div>

                                            <div className="form-group col-6">
                                                <label htmlFor="UPLOAD RESUMES">UPLOAD RESUMES <span className='text-danger fw-bolder'>*</span> (Only pdf, doc, docx file allow)</label>
                                                <input type="file" className="form-control shadow" name="upload_file" id="" onChange={(e) => setFieldValue("upload_file", e.currentTarget.files[0])} aria-describedby="helpId" placeholder="" /><br />
                                                {resumeUrl !== "" ? <button className="btn btn-sm btn-primarry shadow" onClick={window.open(resumeUrl, '_blank')}>view current resume</button> : <></>}
                                                {errors.upload_file && touched.upload_file ? (<p className="text-danger">{errors.upload_file}</p>) : null}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-grid d-md-block text-center text-lg-start my-4 pt-2">
                            {errors.action ? (<p className="text-danger">{errors.action}</p>) : null}
                            {!showHide ? (
                                <button type="button" className="btn btn-primary btn-lg shadow shadow-lg me-2" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={() => { handleSubmit(); setAction("createCandidate") }} >Save</button>
                            ) : (<>
                                <button type="button" className="btn btn-danger btn-lg shadow shadow-lg me-2" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={closeModalFun} >Cancel</button>
                                <button type="button" className="btn btn-warning btn-lg shadow shadow-lg me-2" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={() => { handleSubmit(); setAction("updateCandidate") }} >Update</button>
                            </>)}
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="row p-5">
                <div className='col-xl-12'>
                    <h1 className="text-center">Candidate List</h1>
                    <DataTable
                        className="table table-bordered table-striped"
                        title={<button className="btn btn-sm btn-success me-1" onClick={() => setAddModalShowHide(true)}> Add Candidate </button>}
                        columns={columns}
                        data={filterDatas}
                        pagination
                        dense
                        subHeader
                        subHeaderComponent={<input type="text" className="w-25 form-control" placeholder="search" value={search} onChange={(e) => setSearch(e.target.value)} />}
                        persistTableHead
                        selectableRows
                        fixedHeader
                        selectableRowHighlight
                        highlightOnHover
                    />
                </div>
            </div>
        </div>
    )
}
