import React from 'react'

export const DeliveryModel = () => {
    return (
        <>
            <div className="container-fluid my-5">
                <h1 className="fw-bold my-5 text-center fadeInUp-animation ">Our Delivery Model</h1>
                <p className="paragraphStyle text-center my-4 fadeInUp-animation ">
                    Do you want to improve your recruitment process and yourself? We help you to increase your expertise and broaden your skills. Our training courses focus on the challenges that you – as an HR professional or recruiter – face daily
                </p>
            </div>
            <div className="container-fluid my-5">
                <img src={process.env.PUBLIC_URL + "/assets/webImages/Delivery-Model-Image.png"} className="img-fluid" alt="" style={{ "width": "100%" }} />
            </div>
        </>
    )
}
