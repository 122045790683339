import React from 'react'

export const Achivement = () => {
    return (
        <>
            <div className="container-fluid">
            <h5 className="bannerSubHeading mt-5 text-center fadeInUp-animation ">Achivements</h5>


                <div className="row">
                    <div className='col-sm-3 p-4'>
                        <div className="card border-0 mb-3 p-5 text-center rounded-circle shadow-lg">
                            <div className="card-body text-success">
                                <h5 className="card-title">100+M</h5>
                            </div>
                            <div className="card-footer bg-transparent border-success">TEST 1</div>
                        </div>
                    </div>
                    <div className='col-sm-3 p-4'>
                        <div className="card border-0 mb-3 p-5 text-center rounded-circle shadow-lg">
                            <div className="card-body text-success">
                                <h5 className="card-title">100+M</h5>
                            </div>
                            <div className="card-footer bg-transparent border-success">TEST 1</div>
                        </div>
                    </div>
                    <div className='col-sm-3 p-4'>
                        <div className="card border-0 mb-3 p-5 text-center rounded-circle shadow-lg">
                            <div className="card-body text-success">
                                <h5 className="card-title">100+M</h5>
                            </div>
                            <div className="card-footer bg-transparent border-success">TEST 1</div>
                        </div>
                    </div>
                    <div className='col-sm-3 p-4'>
                        <div className="card border-0 mb-3 p-5 text-center rounded-circle shadow-lg">
                            <div className="card-body text-success">
                                <h5 className="card-title">100+M</h5>
                            </div>
                            <div className="card-footer bg-transparent border-success">TEST 1</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
