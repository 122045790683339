import { useEffect, useContext } from "react";
import { UserAuthenticationDetails } from "../../context/userContext"
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import { signUpSchema } from "../../schemas";

export const Login = () => {

    const history = useHistory();
    const { user, setUser, token, setToken, axiosURL, bacEndUrl, setLoader } = useContext(UserAuthenticationDetails);

    useEffect(() => {
        // console.log(user)
        if (user !== null) {
            // toast.success("Login Successfully", { autoClose: 5000 })
            history.push('/' + user['permission_path'] + '/dashboard')
        }
    }, [user, history, token, bacEndUrl])

    const initialValues = {
        action: "login",
        email: "",
        password: "",
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: signUpSchema,
        onSubmit: (formData) => {
            // console.log(values)
            setLoader(true);
            axios({
                url: axiosURL,
                method: "POST",
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                // console.log(res.data);
                setLoader(false);
                const status = res.data['status'];
                if (status === 1) {
                    sessionStorage.setItem("user", JSON.stringify(res.data['user'][0]));
                    sessionStorage.setItem("token", res.data['token']);

                    setUser(res.data['user'][0]);
                    setToken(res.data['token']);

                    // toast.success("Login Successfully", { autoClose: 5000 })
                    history.push('/' + res.data['user'][0]['permission_path'] + '/dashboard')
                } else {
                    // console.log(res)
                    toast.error(res.data['message'], { autoClose: 5000 })
                }
            }).catch((error) => {
                console.log(error);
            })
        }
    })

    return (
        <section>
            <div className="container-fluid my-5">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-md-9 col-lg-6 col-xl-5">
                        <img src={process.env.PUBLIC_URL + "/assets/webImages/login-vector.png"} className="my-3" height="690px" alt="" />
                    </div>
                    <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                        <form method="POST" onSubmit={handleSubmit}>
                            <div className="text-center">
                                <img src={process.env.PUBLIC_URL + "/assets/webImages/Original.png"} alt="" width="170" height="41" />
                            </div>
                            <h1 className="text-center my-5">LOGIN HERE</h1>
                            <div className="form-outline mb-4">
                                <input type="email" id="email" name="email" className="form-control form-control-lg shadow shadow-lg" placeholder="Enter a valid email address" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                <label className="form-label" htmlFor="email">Email address</label>
                                {errors.email && touched.email ? (<p className="text-danger">{errors.email}</p>) : null}
                            </div>

                            <div className="form-outline mb-3">
                                <input type="password" id="password" name="password" className="form-control form-control-lg shadow shadow-lg" placeholder="Enter password" value={values.password} onChange={handleChange} onBlur={handleBlur} />
                                <label className="form-label" htmlFor="password">Password</label>
                                {errors.password && touched.password ? (<p className="text-danger">{errors.password}</p>) : null}
                            </div>

                            <div className="d-grid text-center text-lg-start my-4 pt-2">
                                <button type="submit" className="btn btn-primary btn-lg shadow shadow-lg" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }}>Login</button>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                <a href="/" className="text-body float-right">Forgot password?</a>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
                <div className="text-white mb-3 mb-md-0">
                    Copyright © 2020. All rights reserved.
                </div>
                <div>
                    <a href="/" className="text-white me-4">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="/" className="text-white me-4">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a href="/" className="text-white me-4">
                        <i className="fab fa-google"></i>
                    </a>
                    <a href="/" className="text-white">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                </div>
            </div>
        </section>
    )
}