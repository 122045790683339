import React from 'react'

export const Contact = () => {
    return (
        <div className='container-fluid'>
            <div className="row p-md-5 p-3" style={{ "backgroundColor": "#e8edf0" }}>
                <div className='col-sm-8'>
                    <h1 className="my-3">Contact Us</h1>
                    <div className="row">
                        <div className="form-group col-sm-6 my-1">
                            <label htmlFor="">FIRST NAME</label>
                            <input type="text" name="" id="" className="form-control rounded-4 shadow" placeholder="" aria-describedby="helpId" />
                        </div>
                        <div className="form-group col-sm-6 my-1">
                            <label htmlFor="">LAST NAME</label>
                            <input type="text" name="" id="" className="form-control rounded-4 shadow" placeholder="" aria-describedby="helpId" />
                        </div>
                        <div className="form-group col-sm-6 my-1">
                            <label htmlFor="">EMAIL ADDRESS</label>
                            <input type="text" name="" id="" className="form-control rounded-4 shadow" placeholder="" aria-describedby="helpId" />
                        </div>
                        <div className="form-group col-sm-6 my-1">
                            <label htmlFor="">MOBILE NUMBER</label>
                            <input type="text" name="" id="" className="form-control rounded-4 shadow" placeholder="" aria-describedby="helpId" />
                        </div>
                        <div className="form-group col-12 my-1">
                            <label htmlFor="">SUBJECT</label>
                            <select className="form-control rounded-4 shadow" name="" id="">
                                <option>Select</option>
                                <option value="I am a job seeker">I am a job seeker</option>
                                <option value="I am an employer">I am an employer</option>
                                <option value="I am a requiter">I am a requiter</option>
                                <option value="I am a freelance requiter">I am a freelance requiter</option>
                                <option value="Employer branding">Employer branding</option>
                                <option value="Employer background verification">Employer background verification</option>
                                <option value="Need requitement forms & formats">Need requitement forms & formats</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className="form-group col-12 my-1">
                            <label htmlFor="">MESSAGE</label>
                            <textarea className="form-control rounded-4 shadow" name="" id="" rows="5"></textarea>
                        </div>
                        <button type="button" className="btn btn-dark btn-lg shadow btnHoverEffect fadeInUp-animation my-3 rounded-4">Submit</button>
                    </div>
                </div>
                <div className='col-sm-4 text-center p-5'>
                    <div>
                        <i className="fa fa-map-marker fa-3x" aria-hidden="true"></i>
                        <p>
                            Bhubaneswar | Hyderabad<br />
                            Bengaluru | Mumbai<br />
                            Noida | Chandigarh<br />
                            Kolkata | Vadodara<br />
                        </p>
                    </div>
                    <div>
                        <i className="fa fa-envelope fa-3x" aria-hidden="true"></i>
                        <p>info@talentcenter.com</p>
                    </div>
                    <div>
                        <i className="fa fa-phone fa-3x" aria-hidden="true"></i>
                        <p>+91 760 905 0223</p>
                    </div>
                    <div>
                        <i className="fa fa-commenting fa-3x" aria-hidden="true"></i>
                        <p>+91 760 905 0223</p>
                    </div>
                    <div>
                        <i className="fa fa-whatsapp fa-3x" aria-hidden="true"></i>
                        <p>+91 760 905 0223</p>
                    </div>
                </div>
            </div>
            <div className='container-fluid my-5'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3740.8204408486354!2d85.8077426!3d20.349035200000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909cc1a73ed83%3A0x18e4e08f7d9ceb1e!2sTalent%20Center%20Network!5e0!3m2!1sen!2sin!4v1704007776678!5m2!1sen!2sin" title='address of talentcenter' width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>


        </div>
    )
}
