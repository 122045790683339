import { useEffect, useContext } from "react";
import { UserAuthenticationDetails } from "../../context/userContext"
import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import { toast } from 'react-toastify';

import { Route, NavLink, Switch } from "react-router-dom";
import { About } from "./about"
import { Banner } from "./banner"
import { DeliveryModel } from "./deliveryModel"
import { Footer } from "./fotter"
import { Ready } from "./ready"
import { Solution } from "./solution"
import { TalentBenifits } from "./talentBenifits"
import { Technology } from "./technology"
import { Testimonial } from "./testimonial"
import { Achivement } from "./achivement";
import { Mission } from "./mission";
import { CommonBanner } from "./commonBanner";
import { Services } from "./services";
import { Referral } from "./referral";
import { Jobs } from "./jobs";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Blog } from "./blog";
import { Contact } from "./contact";
import { Article } from "./article";



export const HomePage = () => {

    const history = useHistory();
    const { user, setUser, token, setToken, axiosURL } = useContext(UserAuthenticationDetails);

    useEffect(() => {
        if (user !== null) {
            // toast.success("Login Successfully", { autoClose: 5000 })
            history.push('/' + user['permission_path'] + '/dashboard')
        }
    }, [user, history, token, setUser, setToken, axiosURL])


    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
                <Container fluid>
                    <NavLink className="navbar-brand" exact to="/">
                        <img src={process.env.PUBLIC_URL + "/assets/webImages/Original.png"} alt="" width="170" height="41" />
                    </NavLink>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <NavLink className="nav-link px-4 fs-5 fw-medium" aria-current="page" exact to="/" activeStyle={{ color: "#005ba6" }}>HOME</NavLink>
                            <NavLink className="nav-link px-4 fs-5 fw-medium" aria-current="page" exact to="/about" activeStyle={{ color: "#005ba6" }}>ABOUT US</NavLink>
                            <NavLink className="nav-link px-4 fs-5 fw-medium" aria-current="page" exact to="/our-services" activeStyle={{ color: "#005ba6" }}>OUR SERVICES</NavLink>
                            {/* <NavLink className="nav-link px-4 fs-5 fw-medium" aria-current="page" exact to="/referral" activeStyle={{ color: "#005ba6" }}>REFERRAL</NavLink> */}
                            {/* <NavLink className="nav-link px-4 fs-5 fw-medium" aria-current="page" exact to="/jobs" activeStyle={{ color: "#005ba6" }}>JOBS</NavLink> */}
                            <NavLink className="nav-link px-4 fs-5 fw-medium" aria-current="page" exact to="/blog" activeStyle={{ color: "#005ba6" }}>BLOGS</NavLink>
                            <NavLink className="nav-link px-4 fs-5 fw-medium" aria-current="page" exact to="/contact" activeStyle={{ color: "#005ba6" }}>CONTACT US</NavLink>
                            <NavLink className="btn btn-lg btn-dark text-light fs-5 btnHoverEffect px-5" aria-current="page" to="/login">Login</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Switch>
                <Route exact path="/">
                    <div className="container-fluid p-5">
                        <Banner />
                        <About />
                        <DeliveryModel />
                        <Solution />
                        <TalentBenifits />
                        <Technology />
                        <Testimonial />
                        <Ready />
                    </div>
                </Route>
                <Route exact path="/about">
                    <CommonBanner pageName="About Us" />
                    <div className="container-fluid p-5">
                        <About />
                        <Achivement />
                        <Mission />
                    </div>
                </Route>
                <Route exact path="/our-services">
                    <CommonBanner pageName="Our Services" />
                    <div className="container-fluid p-5">
                        <Services />
                    </div>
                </Route>
                <Route exact path="/referral">
                    <CommonBanner pageName="Referral" />
                    <div className="container-fluid p-5">
                        <Referral />
                    </div>
                </Route>
                <Route exact path="/jobs">
                    <CommonBanner pageName="Jobs" />
                    <div className="container-fluid p-5">
                        <Jobs />
                    </div>
                </Route>
                <Route exact path="/blog">
                    <CommonBanner pageName="Blog" />
                    <div className="container-fluid p-5">
                        <Blog />
                    </div>
                </Route>
                <Route exact path="/contact">
                    <CommonBanner pageName="Contact Us" />
                    <div className="container-fluid p-5">
                        <Contact />
                    </div>
                </Route>
                <Route exact path="/article/:dynamicData">
                    <CommonBanner pageName="Article" />
                    <div className="container-fluid p-5">
                        <Article />
                    </div>
                </Route>
            </Switch>
            <Footer />
        </>
    )
}