import { useState, useEffect } from 'react'
import axios from "axios";
import { toast } from 'react-toastify';
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2'
import { useFormik } from "formik";
import { roleManagementSchema } from '../../schemas';


export const RoleManagement = (props) => {
    const initialValues = {
        action: "createUserRole",
        user_id: JSON.parse(sessionStorage.getItem("user"))['user_id'],
        user_type: "",
        permission_id: "",
        role_name: "",
        description: "",
        role_id: ""
    }
    const [permissionData, setPermissionData] = useState([]);
    const [action, setAction] = useState("createUserRole");
    const [runUseEffect, setRunUseEffect] = useState(false);
    const [userRoleDataBase, setUserRoleDataBase] = useState([])
    const [filterDatas, setFilterDatas] = useState([]);
    const [search, setSearch] = useState("");
    const [showHide, setShowHide] = useState(false);
    const [filterPermissionData, setFilterPermissionData] = useState([]);

    const userTypeHierarchy = {
        1: "ASSOCIATE",
        2: "CLIENT",
        3: "AGENCY",
    }

    const { values, errors, touched, setFieldValue, handleChange, handleSubmit, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: roleManagementSchema,
        onSubmit: (formData) => {
            axios({
                url: props.axiosURL,
                method: "POST",
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                // console.log(res.data);
                const status = res.data['status'];
                if (status === 1) {
                    if (action === "createUserRole") {
                        toast.success("Data Inserted Successfully", { autoClose: 3000 })
                    } else {
                        toast.success("Data Updated Successfully", { autoClose: 3000 })
                    }
                    setRunUseEffect(!runUseEffect);
                    setShowHide(false);
                    setAction("createUserRole")
					resetForm();
                } else {
                    toast.error(res.data['msg'], { autoClose: 3000 })
                }
            }).catch((error) => {
                console.log(error);
            })
        }
    });

    useEffect(() => {
        axios({
            url: props.axiosURL,
            method: "POST",
            data: { action: "viewAllUserRole" },
            headers: {
                'Content-type': 'multipart/form-data'
            }
        }).then((res) => {
            // console.log(res.data);
            const roleCount = res.data['count'];
            if (roleCount > 0) {
                setUserRoleDataBase(res.data['data'])
                setFilterDatas(res.data['data'])
            }
        }).catch((error) => {
            console.log(error);
        })


        axios({
            url: props.axiosURL,
            method: "POST",
            data: { action: "viewAllUserPermission" },
            headers: {
                'Content-type': 'multipart/form-data'
            }
        }).then((res) => {
            // console.log(res.data);
            const roleCount = res.data['count'];
            if (roleCount > 0) {
                setPermissionData(res.data['data'])
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [props.axiosURL, runUseEffect])


    const fetchFotEdit = (role_id) => {
        setAction('updateUserRole')
        setShowHide(true);
        const filterRoleData = userRoleDataBase.filter((value) => {
            return value.role_id === role_id;
        })[0]
        setFilterPermissionData(permissionData.filter((data) => {
            return data.user_type === filterRoleData.user_type;
        }));
        setFieldValue("action", "updateUserRole")
        setFieldValue("user_id", JSON.parse(sessionStorage.getItem("user"))['user_id'])
        setFieldValue("user_type", filterRoleData.user_type)
        setFieldValue("permission_id", filterRoleData.permission_id)
        setFieldValue("role_name", filterRoleData.role_name)
        setFieldValue("description", filterRoleData.description)
        setFieldValue("role_id", role_id)
    }

    const deleteData = async (role_id) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "Do you want to Delete",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
        });
        if (result.isConfirmed) {
            axios({
                url: props.axiosURL,
                method: "POST",
                data: { action: "deleteUserRole", role_id: role_id, user_id: JSON.parse(sessionStorage.getItem("user"))['user_id'] },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                // console.log(res.data);
                const status = res.data['status'];
                if (status === 1) {
                    toast.success("User Role Deleted Successfully", { autoClose: 3000 })
                    setRunUseEffect(!runUseEffect);
                } else {
                    toast.error(res.data['msg'], { autoClose: 3000 })
                }
            }).catch((error) => {
                console.log(error);
            })
        }
    }



    const columns = [
        {
            name: "Id",
            selector: (row) => row.role_id,
            sortable: true,
            wrap: true,
        },
        {
            name: "User Type",
            selector: (row) => row.user_type === "1" ? ("ASSOCIATE") : row.user_type === "2" ? ("CLIENT") : ("AGENCY"),
            sortable: true,
            wrap: true,
        },
        {
            name: "User Permission",
            selector: (row) => row.permission_name,
            sortable: true,
            wrap: true,
        },
        {
            name: "User Role",
            selector: (row) => row.role_name,
            sortable: true,
            wrap: true,
        },
        {
            name: "User Description",
            selector: (row) => row.description,
            sortable: true,
            wrap: true,
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    <button className="btn btn-sm btn-warning me-1" onClick={() => fetchFotEdit(row.role_id)}>
                        Edit
                    </button>
                    <button className="btn btn-sm btn-danger" onClick={() => deleteData(row.role_id)}>
                        Delete
                    </button>
                </>
            ),
        },
    ]

    useEffect(() => {
        const result = userRoleDataBase.filter((item) => {
            return item.role_name.toLowerCase().match(search.toLowerCase());
        });
        setFilterDatas(result);
    }, [userRoleDataBase, search]);


    return (
        <div className='container-fluid'>
            <div className="row p-5">
                <div className='col-sm-12'>
                    <form method="POST">
                        <h1 className="text-center my-3">USER ROLE MANAGEMENT</h1>
                        <div className="form-outline mb-4 row">
                            <div className="form-group col-4">
                                <label htmlFor="USER TYPE">USER TYPE</label>
                                <select className="form-control shadow shadow-lg" name="user_type" value={values.user_type} onChange={(e) => {
                                    setFilterPermissionData(permissionData.filter((data) => {
                                        return data.user_type === e.target.value;
                                    }));
                                    handleChange(e);
                                }}>
                                    <option value="">SELECT</option>
                                    {
                                        Object.keys(userTypeHierarchy).map((value, key) => {
                                            return (
                                                <option value={value} key={key}>{userTypeHierarchy[value]}</option>
                                            )
                                        })
                                    }
                                </select>
                                {errors.user_type && touched.user_type ? (<p className="text-danger">{errors.user_type}</p>) : null}
                            </div>
                            <div className="form-group col-4">
                                <label htmlFor="USER PERMISSION">USER PERMISSION</label>
                                <select className="form-control shadow shadow-lg" name="permission_id" value={values.permission_id} onChange={handleChange}>
                                    <option value="">SELECT</option>
                                    {
                                        filterPermissionData.map((data, key) => {
                                            return (
                                                <option value={data['permission_id']} key={key}>{data['permission_name']}</option>
                                            )
                                        })
                                    }
                                </select>
                                {errors.permission_id && touched.permission_id ? (<p className="text-danger">{errors.permission_id}</p>) : null}
                            </div>
                            <div className="form-group col-4">
                                <label htmlFor="USER ROLE">USER ROLE</label>
                                <input type="text" id="role_name" name="role_name" className="form-control shadow shadow-lg" placeholder="Enter a Role Name" value={values.role_name} onChange={handleChange} />
                                {errors.role_name && touched.role_name ? (<p className="text-danger">{errors.role_name}</p>) : null}
                            </div>
                            <div className="form-group col-12">
                                <label htmlFor="USER ROLE">USER DESCRIPTION</label>
                                <textarea className="form-control shadow shadow-lg" name="description" rows="3" value={values.description} onChange={handleChange} ></textarea>
                            </div>
                        </div>
                        <div className="d-grid d-md-block text-center text-lg-start my-4 pt-2">
                            {!showHide ? (
                                <button type="button" className="btn btn-primary btn-lg shadow shadow-lg me-2" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={handleSubmit} >Save</button>
                            ) : (<>
                                <button type="button" className="btn btn-danger btn-lg shadow shadow-lg me-2" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={() => { setShowHide(false); resetForm(); setAction('createUserRole'); }} >Cancel</button>
                                <button type="button" className="btn btn-warning btn-lg shadow shadow-lg me-2" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={handleSubmit} >Update</button>
                            </>)}

                        </div>
                    </form>
                </div>
                <div className='col-sm-12'>
                    <h1 className="text-center">Role List</h1>
                    <DataTable
                        className="table table-bordered table-striped"
                        columns={columns}
                        data={filterDatas}
                        pagination
                        dense
                        subHeader
                        subHeaderComponent={<input type="text" className="w-25 form-control" placeholder="search" value={search} onChange={(e) => setSearch(e.target.value)} />}
                        persistTableHead
                        selectableRows
                        fixedHeader
                        selectableRowHighlight
                        highlightOnHover
                    />
                </div>
            </div>
        </div>
    )
}
