import React from 'react'
import { useParams } from 'react-router-dom';


export const Article = () => {
    const { dynamicData } = useParams();
    console.log(dynamicData);
    return (
        <>
            {dynamicData === "why-do-candidates-back-out-how-to-tackle-it" ? (
                <div className='container-fluid'>
                    <div className="row">
                        <h1 className="my-3 fadeInDown-animation text-center">WHY DO CANDIDATES BACK OUT? HOW TO TACKLE IT?</h1>
                        <div className='col-sm-6'>
                            <p className="paragraphStyle my-3 fadeInDown-animation">Candidates backing out is something that every recruiter faces. It adds to the workload of the HR and also contributes to higher hiring charges for the agency. Moreover, the position that desires to be filled remains vacant including in additional liability. </p>
                            <p className="paragraphStyle my-3 fadeInDown-animation">Let’s take a deeper look at the reasons of candidates backing out: </p>

                            <h3 className="my-3 fadeInDown-animation"> 1) Company convinced them to stay.</h3>
                            <p className="paragraphStyle my-3 fadeInDown-animation">Candidates when submitting their resignation, there  is a probability that he/she will continue with the organization subjected to the meeting of their demands. Usually, the organization might comply with some demands of the employee which cancels out their need for a different opportunity. If the candidate is leaving for reasons associated with designation or higher compensation then it’s less complicated for his current organization to hold him back. On the other hand, if the candidates are leaving for reasons associated with work environments and locations, it is harder for Organizations to accommodate the demands of candidates.</p>

                            <h3 className="my-3 fadeInDown-animation">2) A better offer from another organisation</h3>
                            <p className="paragraphStyle my-3 fadeInDown-animation">This might be the most common cause for a candidate to dishonor his/her joining date. As a recruiter or a business organization, you must accept that candidates will examine other offers at the same time as they wait for their  joining date. When we speak approximately a higher offer, they may imply a couple of factors:</p>
                            <ul>
                                <li>Better Compensation</li>
                                <li>Better working conditions (office, location, services)</li>
                                <li>Better role/reputation We ought to uphold more than one factor to what qualifies as a higher offer.</li>
                            </ul>

                            <h3 className="my-3 fadeInDown-animation">3) They’ve evolved a comfort-zone</h3>
                            <p className="paragraphStyle my-3 fadeInDown-animation">Change is tough and for a few applicants the fear of what lies beforehand in their new role may also push them to change their minds.  If a candidate accepts a job provided however then changes his mind, it’s far in particular because of the uncertainty of his new position.  In such scenarios, a bit of reassurance is probably capable of turning the tide in your favor.</p>

                        </div>
                        <div className='col-sm-6 my-5'>
                            <img src={process.env.PUBLIC_URL + "/assets/webImages/skill1.png"} className="continueUpDown-animation img-fluid" alt="" />
                        </div>
                    </div>
                    <div className="row">
                        <h3 className="my-3 fadeInDown-animation">4) They rethink their designation</h3>
                        <p className="paragraphStyle my-3 fadeInDown-animation">While looking for a job, applicants may have accepted roles that they’re not very satisfied with.  When the onboarding date approaches, it’s likely that the potential tasks may not be as attractive to them as they thought they would.</p>
                        <p className="paragraphStyle my-3 fadeInDown-animation">Such situations may be contained through a powerful interview procedure.  It’s critical to gauge the willingness and exhilaration the candidate has toward the role.</p>
                    </div>
                    <div className="row">
                        <h3 className="my-3 fadeInDown-animation">5) Personal emergency can be a hindrance</h3>
                        <p className="paragraphStyle my-3 fadeInDown-animation">The least possible of all possible motives, a private emergency clearly leaves you with only a few alternatives. The great approach in this situation is to be supportive and see if you could expand the onboarding to a later date that is comfortable for both.</p>
                        <p className="paragraphStyle my-3 fadeInDown-animation">Personal emergencies are frequently used as an excuse by applicants who no longer wish to join the organization. Hence, it’s best to be a bit conservative when extending supportive courtesies in the direction of the candidate.</p>
                    </div>
                    <div className="row">
                        <h3 className="my-3 fadeInDown-animation text-center">TO TURN AROUND THE SITUATION AND AVOID CANDIDATES BACKING OUT BELOW TIPS CAN HELP!!</h3>
                        <div className="col-sm-6">
                            <h3 className="my-3 fadeInDown-animation">1) Sell the vision</h3>
                            <p className="paragraphStyle my-3 fadeInDown-animation">Candidates ought to purchase into the vision of the organisation and it’s up to you to help them to do so when you communicate with them.  Speak about the mission, goals  and future plans of the company and what contributions  you wish the candidate to make in order to meet company plans and objectives.  Ensure that the candidate’s dreams are aligned with the employer’s desires.  If the candidate is enthusiastic about his/her role in shaping the vision of the company, you’ll not often locate him/her backing out.</p>
                            <h3 className="my-3 fadeInDown-animation">2) Not every person desire to join in massive corporates</h3>
                            <p className="paragraphStyle my-3 fadeInDown-animation">While the majority of applicants that you’ll come upon will need to work in a low-pressure, established company process, there are a handful of potential candidates who love the idea of a Startup or SME.  These  applicants choose to work in an environment where they can be part of a growth tale.  If you’re a startup, seeking these candidates will leave you with a lesser risk of a candidate backout.</p>

                            <h3 className="my-3 fadeInDown-animation">3) Negotiations and notice period buyouts</h3>
                            <p className="paragraphStyle my-3 fadeInDown-animation">If the organization has the policy of  buyout of an applicant’s notice period, it’s better to implement the policy given the fact that it’ll compel them to join the organization.  This might straight away lessen the chances of them stepping back. If a buyout isn’t feasible, you may always help the candidate negotiate along with his/her current employer to shorten the notice period. This helps in reducing the candidate backout rates.</p>

                        </div>
                        <div className="col-sm-6">
                            <img src={process.env.PUBLIC_URL + "/assets/webImages/skill2.png"} className="continueUpDown-animation img-fluid" alt="" />
                        </div>
                    </div>
                    <div className="row">
                        <h3 className="my-3 fadeInDown-animation">4) Move quickly</h3>
                        <p className="paragraphStyle my-3 fadeInDown-animation">Time kills deals! Don’t give your candidate time to look for different options. Push the process forward as quickly as possible so that you can onboard potential candidates  quickly.</p>
                    </div>
                    <div className="row">
                        <h3 className="my-3 fadeInDown-animation">5) Ask hard questions and probe the answers</h3>
                        <p className="paragraphStyle my-3 fadeInDown-animation">To find out if your candidate is at risk of backing out, you need to dig into their motive for applying to your job in the first place. </p>
                        <p className="paragraphStyle my-3 fadeInDown-animation">Frequent communication with  your candidates will instill the sense of “being part of the organization”. This approach will have a positive impact on the candidate and in all probability candidates will find more reasons to join the organization. </p>
                    </div>

                </div>
            ) : dynamicData === "recruitment-methods-traditional-vs-modern-Whats-the-best" ? (
                <div className='container-fluid'>
                    <div className="row">
                        <h1 className="my-3 fadeInDown-animation text-center">RECRUITMENT METHODS: TRADITIONAL VS MODERN WHAT’S THE BEST?</h1>
                        <div className='col-sm-6'>
                            <p className="paragraphStyle my-1 fadeInDown-animation">Human resource is the most crucial asset of any company and the process of acquiring this asset is more crucial!!Before looking at the evaluation of traditional and modern recruitment methods let’s first look at what are different methods in each!!</p>

                            <h3 className="my-1 fadeInDown-animation">Traditional Recruitment Methods:</h3>
                            <ul className="paragraphStyle my-1 fadeInDown-animation p-5">
                                <li><h3>Newspaper-</h3><span> Recruiters have been using this since long for posting the job vacancies.</span></li>
                                <li><h3>Internal Hiring-</h3><span>Promoting one of the trusted existing employees to a hire position is the most accurate thing to do in terms of reliability.</span></li>
                                <li><h3>Local employment office-</h3><span>To get a variety of localites to have a look at the vacant job, putting up the job in LEOs fill the purpose.</span></li>
                                <li><h3>Temp agencies-</h3><span>one of the oldest methods of recruitment is to take help of a temporary employment agency to find potentials for the company.</span></li>
                            </ul>
                        </div>
                        <div className='col-sm-6 my-5'>
                            <img src={process.env.PUBLIC_URL + "/assets/webImages/skill3.png"} className="continueUpDown-animation img-fluid" alt="" />
                        </div>
                    </div>
                    <div className="row">
                        <h3 className="my-1 fadeInDown-animation">Modern Recruitment Methods:</h3>
                        <ul className="paragraphStyle my-1 fadeInDown-animation p-5">
                            <li><h3>Smart phones-</h3><span>Easiest and quickest way to connect with anyone in today’s time is over a call or text and same goes for the candidates.</span></li>
                            <li><h3>Social media/apps-</h3><span>Various social media platforms such as Facebook, Instagram, twitter, LinkedIn offers a big pool of candidates and an inexpensive way to post the jobs. It has a vast scope of quick engagement with the potential candidate. Along with these What’s app and telegram groups are the fastest way to viral a job post!!</span></li>
                            <li><h3>Event recruitment-</h3><span>To reflect company’s ethics and values and to gain popularity through sponsoring an event is very common these days, and why not? Since it’s a great way to make a network of like minded people which later on helps in recruiting.</span></li>
                            <li><h3>Re-recruiting-</h3><span>Experienced and efficient employees who left their job due to any personal or organizational issue can be welcomed back to fill a position. It is a very convenient way as it saves training cost and reliability is high too!!</span></li>
                            <li><h3>Online recruitment-</h3><span>There are several job websites these days in the market which is the most frequently used method today for recruitment as these websites offer a vast pool of candidates to look at (ex- monster, indeed). Apart from this many recruitment agencies have their own website and job boards where candidates can easily fill a form and apply for that job making it easier for recruiters to get candidates on hand instead of hunting.</span></li>
                            <li><h3>Scouting-</h3><span>Companies send their representatives to universities or colleges to give details about the job vacancies. Popularly heard campus placements is a related term to this.</span></li>
                        </ul>
                        <p className="paragraphStyle my-1 fadeInDown-animation">Human resource is the most crucial asset of any company and the process of acquiring this asset is more crucial!!Before looking at the evaluation of traditional and modern recruitment methods let’s first look at what are different methods in each!!</p>
                    </div>

                    <div className="row my-3">
                        <table class="table table-striped">
                            <thead>
                                <tr className='fw-bolder table-success'>
                                    <td> S.No. </td>
                                    <td> BASIS </td>
                                    <td> TRADITIONAL </td>
                                    <td> MODERN </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> 1</td>
                                    <td> Time</td>
                                    <td> In newspapers and other print media most of the time goes between sending and receiving applications. Then comes the application shortlisting, which is another time to take on the task.</td>
                                    <td> Modern methods are faster. Jobs can be submitted immediately, and requests are received in real time. Filtering is also faster, by filtering them using software.
                                    </td>
                                </tr>
                                <tr>
                                    <td> 2</td>
                                    <td> Money</td>
                                    <td> A fixed commission rate is usually charged, both  from  an organization and job seeker. This is more expensive than the modern method.</td>
                                    <td> Here, the process is much cheaper. The hiring consultant charges a very small  amount , based on the package, and performs all the functions of the internal hiring team.
                                    </td>
                                </tr>
                                <tr>
                                    <td> 3</td>
                                    <td> Processing</td>
                                    <td> Sequential processing. Error in one phase will continue to the next phase</td>
                                    <td> Parallel processing.The error can be detected and corrected at that moment. It Saves the risk of forwarding errors.
                                    </td>
                                </tr>
                                <tr>
                                    <td> 4</td>
                                    <td> Job Posting Duration</td>
                                    <td> Print media or hiring offices have a limited time of job visibility, perhaps a week or more. That reduces the number of candidates one receives.</td>
                                    <td> Posting to a twitter or company web page can last as long as it wants; which increases the chances of drawing more people to apply.
                                    </td>
                                </tr>
                                <tr>
                                    <td> 5</td>
                                    <td> Reach</td>
                                    <td> Reach is limited to the geographical boundaries-state or national or only the network we have. Which gives a limited no. of candidates to choose from.</td>
                                    <td> Modern methods offer wider access. It Reaches people outside the local and state boundaries and provides a large pool of skilled and trained people.
                                    </td>
                                </tr>
                                <tr>
                                    <td> 6</td>
                                    <td> Depth and detail</td>
                                    <td> Do not provide a way to assess a candidate’s suitability and eligibility apart from the job criteria. Knowing his/her personality, therefore, becomes difficult.</td>
                                    <td> The employer may ask questions to measure the person’s ability and pre-evaluate applicants at that very time. Therefore, only those who meet the hiring requirement will proceed to another level of hiring.
                                    </td>
                                </tr>
                                <tr>
                                    <td> 7</td>
                                    <td> Competition</td>
                                    <td> In today’s world, using traditional media, there is very little scope of getting popularity or showcase a company’s existence. Since they do not have a platform for mass communication.</td>
                                    <td> Social media presence is very necessary in today’s world as it is the quickest way to reach a wide variety of people. Therefore, making us competent in the market.
                                    </td>
                                </tr>
                                <tr>
                                    <td> 8</td>
                                    <td> Technical competence</td>
                                    <td> Traditional methods are pretty simple to use as it doesn’t involve complex software but it’s all upon offline strategies one follows.</td>
                                    <td> Using the power of the Internet or social media to hire people requires technical expertise and proper management.&nbsp;
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <p className="paragraphStyle my-3 fadeInDown-animation">With the advent of new technologies like ATS software, body language assessment programs, virtual recruitment tools, social media analysis, machine learning tools, Voice recognition, traditional and modern methods of recruitment seem to be in two different worlds. </p>
                            <p className="paragraphStyle my-3 fadeInDown-animation">It may seem that the finger is pointing towards the modern method, but there are times when one would like to go the normal way. For example, small organizations prefer to go with the traditional method. Also, using modern methods requires a certain level of technology that may not be available in most organizations. It is best to list the needs and priorities before choosing any one option. </p>
                            <p className="paragraphStyle my-3 fadeInDown-animation">Therefore, it is entirely the decision of the organization or recruiter that it wants to hire in a normal way or non-traditional way or in a combination of both!. </p>
                        </div>
                        <div className="col-sm-6">
                            <img src={process.env.PUBLIC_URL + "/assets/webImages/skill4.png"} className="continueUpDown-animation img-fluid" alt="" />
                        </div>
                    </div>


                </div>
            ) : dynamicData === "want-to-be-a-successful-recruiter-here-is-how" ? (
                <div className='container-fluid'>
                    <div className="row">
                        <h1 className="my-3 fadeInDown-animation text-center">HERE’S HOW TO BE A SUCCESSFUL RECRUITER</h1>
                        <div className='col-sm-6'>
                            <h3 className="my-1 fadeInDown-animation">1)Be a good connector</h3>
                            <p className="paragraphStyle my-1 fadeInDown-animation">As a recruiter, you must be a good connector, who enjoys meeting new people and take most of every networking  opportunity:</p>

                            <ul className="paragraphStyle my-1 fadeInDown-animation p-5">
                                <li>It  helps you to build your credibility and reliability.</li>
                                <li>Attract new clients and job seekers.</li>
                                <li>Your old and current clients will return to you without looking elsewhere once you have demonstrated that you perform professionally, effectively, and consistently.</li>
                                <li>It will also save your time because you will be able to focus on your current clients rather than continuously chasing new ones.</li>
                                <li>Good relations with your job seekers will get you more candidates as they  will tell their friends and relatives about you if they are satisfied with the work you found for them.</li>
                            </ul>
                        </div>
                        <div className='col-sm-6 my-5'>
                            <img src={process.env.PUBLIC_URL + "/assets/webImages/skill5.png"} className="continueUpDown-animation img-fluid" alt="" />
                        </div>
                    </div>
                    <div className="row">
                        <h3 className="my-1 fadeInDown-animation">2)Be a good listener</h3>
                        <p className="paragraphStyle my-1 fadeInDown-animation">Being a good listener is essential for a recruitment consultant. The more you listen carefully and thoroughly the more questions you have. The more questions you ask, the more you will learn.</p>

                        <ul className="paragraphStyle my-1 fadeInDown-animation p-5">
                            <li>You will indeed be able to grasp the demands of both the job seeker and the client.</li>
                            <li>The better you understand the client’s and job seekers’ needs, the easier it will be to identify the ideal fit.</li>
                            <li>This will enable you to make wiser decisions and consistently find the perfect match.</li>
                        </ul>
                    </div>
                    <div className="row">
                        <h3 className="my-1 fadeInDown-animation">3)Target driven</h3>
                        <p className="paragraphStyle my-1 fadeInDown-animation">If you are not a goal-oriented person, you should seriously consider whether or not this is the work for you because the recruiting market is so competitive and results-driven.</p>

                        <ul className="paragraphStyle my-1 fadeInDown-animation p-5">
                            <li>Typically, recruitment agencies offer basic compensation as well as performance-related bonuses, sometimes known as OTE – on-target earnings.</li>
                            <li>It’s critical that the recruiter can handle pressure, is goal-oriented, ambitious, and results-driven.</li>
                        </ul>
                    </div>
                    <div className="row">
                        <h3 className="my-1 fadeInDown-animation">4)Manage your time well</h3>
                        <p className="paragraphStyle my-1 fadeInDown-animation">Successful recruiters are more concerned with being productive than with being occupied. Hence, it is important to set priorities for each day. Organizing your day around activities that yield results is a better use of time.</p>
                    </div>
                    <div className="row">
                        <h3 className="my-1 fadeInDown-animation">5)Be a multi-tasker</h3>
                        <p className="paragraphStyle my-1 fadeInDown-animation">Recruiters are frequently working on multiple assignments at the same time for a variety of customers, thus this job requires you to be a multi-tasker. Due to a heightened level of urgency to fill a position, specific roles may need to be prioritized; thus, you must be competent at managing your tasks properly.</p>
                    </div>
                    <div className="row">
                        <h3 className="my-1 fadeInDown-animation">6)Adaptability/Agility</h3>
                        <p className="paragraphStyle my-1 fadeInDown-animation">Recruiting may be challenging for you if you are rigid and have a hard time changing your mind. The ability to adapt, modify, and be flexible are the vital attributes exhibited by the top recruiters.</p>

                        <ul className="paragraphStyle my-1 fadeInDown-animation p-5">
                            <li>You must adjust your communication style to meet the needs of your clients or candidates.</li>
                            <li>Update your strategy in response to changes in the job market.</li>
                            <li>Be willing to learn about new technologies that affect the markets you serve.</li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className='col-sm-6'>
                            <h3 className="my-1 fadeInDown-animation">7)Pay attention to the details</h3>
                            <p className="paragraphStyle my-1 fadeInDown-animation">As a recruiter, keep in mind that none of the people involved are obligated to collaborate with you. But they should want to work with you because you make the process easier, faster, and more successful. This is true only if you adopt a meticulous approach to offering excellent service.</p>

                            <h3 className="my-1 fadeInDown-animation">8)Be patient</h3>
                            <p className="paragraphStyle my-1 fadeInDown-animation">Things don’t always go as planned; therefore, the recruiter must be patient. Though this can be aggravating at times if interviews must be rescheduled or if the appropriate candidate isn’t found in the first round, but it’s critical to maintain your composure and maintain a professional demeanor.</p>
                        </div>
                        <div className='col-sm-6'>
                            <img src={process.env.PUBLIC_URL + "/assets/webImages/skill6.png"} className="continueUpDown-animation img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            ) : (
                <h1 className='text-center my-5'>no record found</h1>
            )}
        </>
    )
}
