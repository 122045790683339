import React from 'react'

export const Mission = () => {
    return (
        <>
            <div className="container-fluid my-5">
                <h5 className="bannerSubHeading mt-5 text-center fadeInUp-animation ">Missions</h5>

                <div className="row">
                    <div className='col-sm-6 p-4'>
                        <div className="card mb-3 shadow-lg p-3 fadeZoomIn-animation">
                            <div className="row g-0">
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h3 className="card-title fw-bold">Mission Name</h3>
                                        <p className="card-text paragraphStyle">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                        <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <img src={process.env.PUBLIC_URL + "/assets/webImages/Hero-Section-Image.jpg"} className="img-fluid rounded-start" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 p-4'>
                        <div className="card mb-3 shadow-lg p-3 fadeZoomIn-animation">
                            <div className="row g-0">
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h3 className="card-title fw-bold">Mission Name</h3>
                                        <p className="card-text paragraphStyle">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                        <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <img src={process.env.PUBLIC_URL + "/assets/webImages/Hero-Section-Image.jpg"} className="img-fluid rounded-start" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}


