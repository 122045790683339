import { useState, useEffect } from 'react'
import axios from "axios";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import { useFormik } from "formik";
import { userManagementSchema } from '../../schemas';

export const UserManagement = (props) => {

	const [userRole, setUserRole] = useState([])
	const [action, setAction] = useState("createOtherUser");
	const [filterRole, setfilterRole] = useState([])
	const [user, setUser] = useState([])
	const [entityDetails, setEntityDetails] = useState([])
	const [filterEntityDetails, setFilterEntityDetails] = useState([])
	const [runUseEffect, setRunUseEffect] = useState(false);
	const [showHide, setShowHide] = useState(false);

	const initialValues = {
		action: "createOtherUser",
		user_id: JSON.parse(sessionStorage.getItem("user"))['user_id'],
		main_entity_type: "",
		entity_id: "",
		first_name: "",
		last_name: "",
		gender: "",
		mobile: "",
		email: "",
		password: "",
		role_id: "",
		description: "",
	}
	const userTypeHierarchy = {
		1: "ASSOCIATE",
		2: "CLIENT",
		3: "AGENCY",
	}

	const { values, errors, touched, setFieldValue, handleChange, handleSubmit, resetForm } = useFormik({
		initialValues: initialValues,
		validationSchema: userManagementSchema,
		onSubmit: (formData) => {

			console.log(formData)
			axios({
				url: props.axiosURL,
				method: "POST",
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then((res) => {
				// console.log(res.data);
				const status = res.data['status'];
				if (status === 1) {
					if (action === "createOtherUser") {
						toast.success("Data Inserted Successfully", { autoClose: 3000 })
					} else {
						toast.success("Data Updated Successfully", { autoClose: 3000 })
					}
					setRunUseEffect(!runUseEffect);
					setShowHide(false);
					setAction("createOtherUser");
					resetForm();
				} else {
					toast.error(res.data['msg'], { autoClose: 3000 })
				}
			}).catch((error) => {
				console.log(error);
			})
		}
	});

	useEffect(() => {
		axios({
			url: props.axiosURL,
			method: "POST",
			data: { action: "viewAllUserRole" },
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then((res) => {
			// console.log(res.data);
			const roleCount = res.data['count'];
			if (roleCount > 0) {
				setUserRole(res.data['data'])
				setfilterRole(res.data['data'])
			}
		}).catch((error) => {
			console.log(error);
		})

		axios({
			url: props.axiosURL,
			method: "POST",
			data: { action: "viewAllClient" },
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then((res) => {
			console.log(res.data);
			const roleCount = res.data['count'];
			if (roleCount > 0) {
				setEntityDetails(res.data['data'])
				setFilterEntityDetails(res.data['data'])
			}
		}).catch((error) => {
			console.log(error);
		})

	}, [props.axiosURL])

	useEffect(() => {
		axios({
			url: props.axiosURL,
			method: "POST",
			data: { action: "viewAllOtherUsers" },
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then((res) => {
			console.log(res.data);
			const roleCount = res.data['count'];
			if (roleCount > 0) {
				setUser(res.data['data'])
			}
		}).catch((error) => {
			console.log(error);
		})
	}, [props.axiosURL, runUseEffect])


	const fetchFotEdit = (user_id) => {
		setAction('updateOtherUsers')
		setShowHide(true);
		const filterUserData = user.filter((value) => {
			return value.user_id === user_id;
		})[0]

		setFieldValue("action", "updateOtherUsers")
		setFieldValue("edited_by", JSON.parse(sessionStorage.getItem("user"))['user_id'])
		setFieldValue("main_entity_type", filterUserData.user_type)
		setFieldValue("entity_id", filterUserData.entity_id)
		setFieldValue("first_name", filterUserData.first_name)
		setFieldValue("last_name", filterUserData.last_name)
		setFieldValue("gender", filterUserData.gender)
		setFieldValue("mobile", filterUserData.mobile)
		setFieldValue("email", filterUserData.email)
		setFieldValue("password", filterUserData.password)
		setFieldValue("role_id", filterUserData.role_id)
		setFieldValue("description", filterUserData.description)
		setFieldValue("user_id", user_id)
	}

	const deleteData = async (user_id) => {
		const result = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want to Delete",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Confirm',
			cancelButtonText: 'Cancel',
		});
		if (result.isConfirmed) {
			axios({
				url: props.axiosURL,
				method: "POST",
				data: { action: "deleteOtherUsers", user_id: user_id, edited_by: JSON.parse(sessionStorage.getItem("user"))['user_id'] },
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then((res) => {
				// console.log(res.data);
				const status = res.data['status'];
				if (status === 1) {
					toast.success("User Deleted Successfully", { autoClose: 3000 })
					setRunUseEffect(!runUseEffect);
				} else {
					toast.error(res.data['msg'], { autoClose: 3000 })
				}
			}).catch((error) => {
				console.log(error);
			})
		}
	}



	return (
		<div className='container-fluid'>
			<div className="row">
				<div className='col-sm-12'>
					<form method="POST">
						<h1 className="text-center my-3">USER MANAGEMNT</h1>
						<div className="form-outline mb-4 row p-4">
							<div className="form-group col-sm-4">
								<label className='fw-bold my-2' htmlFor="USER TYPE">USER TYPE</label>
								<select className="form-control shadow" name="main_entity_type" value={values.main_entity_type} onChange={
									(e) => {
										setfilterRole(userRole.filter((data) => {
											return data.user_type === e.target.value;
										}));
										handleChange(e);
									}
								}>
									<option value="">SELECT</option>
									{
										Object.keys(userTypeHierarchy).map((value, key) => {
											return (
												<option value={value} key={key}>{userTypeHierarchy[value]}</option>
											)
										})
									}
								</select>
								{errors.main_entity_type && touched.main_entity_type ? (<p className="text-danger">{errors.main_entity_type}</p>) : null}
							</div>
							<div className="form-group col-sm-4">
								<label className='fw-bold my-2' htmlFor="USER ROLE">USER ROLE</label>
								<select className="form-control shadow shadow-lg" name="role_id" value={values.role_id} onChange={
									(e) => {
										setFilterEntityDetails(entityDetails.filter((data) => {
											return data.main_entity_type === values.main_entity_type;
										}));
										handleChange(e);
									}
								}>
									<option value="">SELECT</option>
									{
										filterRole.map((value, key) => {
											return (
												<option value={value['role_id']} key={key}> {value['role_name']} </option>
											)
										})
									}
								</select>
								{errors.role_id && touched.role_id ? (<p className="text-danger">{errors.role_id}</p>) : null}
							</div>
							<div className="form-group col-sm-4">
								<label className='fw-bold my-2' htmlFor="USER ROLE">CLIENT NAME</label>
								<select className="form-control shadow shadow-lg" name="entity_id" value={values.entity_id} onChange={handleChange}>
									<option value="">SELECT</option>
									{
										filterEntityDetails.map((value, key) => {
											return (
												<option value={value['entity_id']} key={key}> {value['entity_ligal_name']} </option>
											)
										})
									}
								</select>
								{errors.entity_id && touched.entity_id ? (<p className="text-danger">{errors.entity_id}</p>) : null}
							</div>
							<div className="form-group col-sm-4">
								<label className='fw-bold my-2' htmlFor="FIRST NAME">FIRST NAME</label>
								<input type="text" className="form-control shadow shadow-lg" name="first_name" id="" value={values.first_name} onChange={handleChange} aria-describedby="helpId" placeholder="" />
								{errors.first_name && touched.first_name ? (<p className="text-danger">{errors.first_name}</p>) : null}
							</div>
							<div className="form-group col-sm-4">
								<label className='fw-bold my-2' htmlFor="LAST NAME">LAST NAME</label>
								<input type="text" className="form-control shadow shadow-lg" name="last_name" id="" value={values.last_name} onChange={handleChange} aria-describedby="helpId" placeholder="" />
								{errors.last_name && touched.last_name ? (<p className="text-danger">{errors.last_name}</p>) : null}
							</div>
							<div className="form-group col-sm-4">
								<label className='fw-bold my-2' htmlFor="GENDER">GENDER</label><br />

								<div className="form-check form-check-inline">
									<input type="radio" name="gender" id="userGender" className="form-check-input" value="1" checked={values.gender === "1"} onChange={handleChange} />
									<label className="form-check-label" htmlFor="">Male</label><br />
								</div>
								<div className="form-check form-check-inline">
									<input type="radio" name="gender" id="userGender" className="form-check-input" value="2" checked={values.gender === "2"} onChange={handleChange} />
									<label className="form-check-label" htmlFor="">Female</label>
								</div>
								<div className="form-check form-check-inline">
									<input type="radio" name="gender" id="userGender" className="form-check-input" value="3" checked={values.gender === "3"} onChange={handleChange} />
									<label className="form-check-label" htmlFor="">Other</label>
								</div>
								{errors.gender && touched.gender ? (<p className="text-danger">{errors.gender}</p>) : null}
							</div>
							<div className="form-group col-sm-4">
								<label className='fw-bold my-2' htmlFor="MOBILE NO.">MOBILE NO.</label>
								<input type="text" className="form-control shadow shadow-lg" name="mobile" id="" value={values.mobile} onChange={(e) => { handleChange(e); setFieldValue("password", e.target.value) }} aria-describedby="helpId" placeholder="" />
								{errors.mobile && touched.mobile ? (<p className="text-danger">{errors.mobile}</p>) : null}
							</div>
							<div className="form-group col-sm-4">
								<label className='fw-bold my-2' htmlFor="EMAIL ID">EMAIL ID</label>
								<input type="email" className="form-control shadow shadow-lg" name="email" id="" value={values.email} onChange={handleChange} aria-describedby="helpId" placeholder="" />
								{errors.email && touched.email ? (<p className="text-danger">{errors.email}</p>) : null}
							</div>
							<div className="form-group col-sm-4">
								<label className='fw-bold my-2' htmlFor="PASSWORD">PASSWORD</label>
								<input type="text" className="form-control shadow shadow-lg" name="password" id="" value={values.password} aria-describedby="helpId" placeholder="" readOnly disabled />
								{errors.password && touched.password ? (<p className="text-danger">{errors.password}</p>) : null}
							</div>
							<div className="form-group">
								<label className='fw-bold my-2' htmlFor="DESCRIPTION">DESCRIPTION</label>
								<textarea className="form-control shadow shadow-lg" name="description" rows="3" value={values.description} onChange={handleChange} ></textarea>
							</div>
						</div>

						<div className="d-grid d-md-block text-center text-lg-start my-4 pt-2">
							{!showHide ? (
								<button type="button" className="btn btn-primary btn-lg shadow shadow-lg" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={handleSubmit} >Save</button>
							) : (<>
								<button type="button" className="btn btn-danger btn-lg shadow shadow-lg me-2" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={() => { setShowHide(false); resetForm(); setAction("createOtherUser") }} >Cancel</button>
								<button type="button" className="btn btn-warning btn-lg shadow shadow-lg me-2" style={{ paddingLeft: "40px", paddingRight: "2.5rem" }} onClick={handleSubmit}>Update</button>
							</>)}
						</div>
					</form>
				</div>
			</div>
			<div className='row'>

				{user.map((data, key) => {
					return (
						<div className='col-sm-4' key={key}>
							{/* <div className="card shadow-lg border-0 p-4 text-center" style={{ background: `linear-gradient(-30deg, #cbe4f9 35%, rgba(0, 0, 0, 0)70%)` }}> */}
							<div className="card shadow-lg border-0 p-4 text-center">
								<div className="card-body">
									<h5 className="card-title">
										<i className="fa fa-user me-2" aria-hidden="true"></i>
										{data.first_name + " " + data.last_name + " (" + data.role_name + ")"}
									</h5>
									<h6 className="card-subtitle mb-2 text-body-secondary">
										<i className="fa fa-users me-2" aria-hidden="true"></i>
										<span className='fw-bolder'>User Type :</span>{userTypeHierarchy[data.user_type]}
									</h6>
									<h6 className="card-text mb-2 text-body-secondary">
										<i className="fa fa-file-text me-2" aria-hidden="true"></i>
										<span className='fw-bolder'>User Permission :</span> {data.permission_name}
									</h6>
									<h6 className="card-subtitle mb-2 text-body-secondary">
										<i className="fa fa-users me-2" aria-hidden="true"></i>
										<span className='fw-bolder'>Entity Ligal Name:</span>{data.entity_ligal_name}
									</h6>

									<h6 className="card-text mb-2 text-body-secondary">
										<i className="fa fa-envelope-square me-2" aria-hidden="true"></i>
										<span className='fw-bolder'>User Email :</span> {data.email}
									</h6>
									<h6 className="card-text mb-2 text-body-secondary">
										<i className="fa fa-phone-square me-2" aria-hidden="true"></i>
										<span className='fw-bolder'>User Mobile :</span> {data.mobile}
									</h6>

									<p className="card-text"><span className='fw-bolder'>User Description :</span> {data.description}</p>
									<div className="d-grid gap-2 d-md-block">
										<button className="btn btn-sm alert alert-warning rounded-4 w-25 me-2 p-2 fw-bolder border-2" onClick={() => fetchFotEdit(data.user_id)}> Edit </button>
										<button className="btn btn-sm alert alert-danger rounded-4 w-25 p-2 fw-bolder border-2" onClick={() => deleteData(data.user_id)}> Delete </button>
									</div>
								</div>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

