import React from 'react'
import { DeliveryModel } from './deliveryModel'
import { Testimonial } from './testimonial'
import { Ready } from './ready'

export const Services = () => {
    return (
        <div className="container-fluid my-5">
            <div className="row">
                <div className="col-sm-12 my-5">
                    <h5 className="bannerSubHeading text-center my-4">Recruitment Services</h5>
                    <p className="paragraphStyle text-center">
                        We provide full-cycle recruiting services that free up time and save money.
                    </p>
                    <p className="paragraphStyle text-center">
                        Find the talent you need to keep growing your business.
                    </p>
                    <div className='text-center'>
                        <button type="button" className="btn btn-dark btn-lg shadow btnHoverEffect fadeInUp-animation">Get In Touch</button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 my-5">
                    <h5 className="bannerSubHeading text-center">Pay-Per-Hire Service Plans</h5>
                    <h1 className="fw-bold my-4 text-center">Explore Our Solutions</h1>
                    <p className="paragraphStyle text-center"> box coulor needs to be changed. </p>
                </div>
                {/* <div className="col-sm-3 my-5">
                    <div className="card my-1 p-5 fadeLeftIn-animation" style={{ "backgroundColor": "#00ad66", "borderRadius": "50px 00px 50px 50px" }}>
                        <h1 className="text-light text-center my-1">Super-Lite</h1>
                        <p className="text-light paragraphStyle text-center my-1"> Pay Per Hire </p>
                        <p className="text-light paragraphStyle text-center my-1">One-time replacement guarantee; 15 days from DOJ</p>
                        <p className="text-light paragraphStyle text-center my-1">Recommended for MSMEs</p>
                        <p className="text-light paragraphStyle text-center my-1">Pay by 7 day from DOJ</p>
                        <div className="text-center">
                            <button type="button" className="btn btn-dark btn-lg shadow btnHoverEffect">Get In Touch</button>
                        </div>
                    </div>
                </div> */}
                <div className="col-sm-3 my-5">
                    <div className="card my-1 p-5 fadeLeftIn-animation" style={{ "backgroundColor": "#005ba6", "borderRadius": "50px 0px 50px 50px" }}>
                        <h1 className="text-light text-center my-1">Lite</h1>
                        <p className="text-light paragraphStyle text-center my-1"> Pay Per Hire </p>
                        <p className="text-light paragraphStyle text-center my-1">One-time replacement guarantee; 30 days from DOJ</p>
                        <p className="text-light paragraphStyle text-center my-1">Recommended for MSMEs</p>
                        <p className="text-light paragraphStyle text-center my-1">Pay by 15 day from DOJ</p>
                        <div className="text-center">
                            <button type="button" className="btn btn-dark btn-lg shadow btnHoverEffect">Get In Touch</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 my-5">
                    <div className="card my-1 p-5 fadeLeftIn-animation" style={{ "backgroundColor": "#00ad66", "borderRadius": "0px 50px 50px 50px" }}>
                        <h1 className="text-light text-center my-1">De-Lite</h1>
                        <p className="text-light paragraphStyle text-center my-1"> Pay Per Hire </p>
                        <p className="text-light paragraphStyle text-center my-1">One-time replacement guarantee; 60 days from DOJ</p>
                        <p className="text-light paragraphStyle text-center my-1">Recommended for Startups</p>
                        <p className="text-light paragraphStyle text-center my-1">Pay by 30 day from DOJ</p>
                        <div className="text-center">
                            <button type="button" className="btn btn-dark btn-lg shadow btnHoverEffect">Get In Touch</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 my-5">
                    <div className="card my-1 p-5 fadeLeftIn-animation" style={{ "backgroundColor": "#005ba6", "borderRadius": "50px 0px 50px 50px" }}>
                        <h1 className="text-light text-center my-1">Standard</h1>
                        <p className="text-light paragraphStyle text-center my-1"> Pay Per Hire </p>
                        <p className="text-light paragraphStyle text-center my-1">One-time replacement guarantee; 90 days from DOJ</p>
                        <p className="text-light paragraphStyle text-center my-1">Recommended for Enterprises</p>
                        <p className="text-light paragraphStyle text-center my-1">Pay by 45 day from DOJ</p>
                        <div className="text-center">
                            <button type="button" className="btn btn-dark btn-lg shadow btnHoverEffect">Get In Touch</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 my-5">
                    <div className="card my-1 p-5 fadeLeftIn-animation" style={{ "backgroundColor": "#00ad66", "borderRadius": "0px 50px 50px 50px" }}>
                        <h1 className="text-light text-center my-1">Premium</h1>
                        <p className="text-light paragraphStyle text-center my-1"> Pay Per Hire </p>
                        <p className="text-light paragraphStyle text-center my-1">One-time replacement guarantee; 120 days from DOJ</p>
                        <p className="text-light paragraphStyle text-center my-1">Recommended for Enterprises</p>
                        <p className="text-light paragraphStyle text-center my-1">Pay by 60 day from DOJ</p>
                        <div className="text-center">
                            <button type="button" className="btn btn-dark btn-lg shadow btnHoverEffect">Get In Touch</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <DeliveryModel />
            </div>
            <div className='row'>
                <div className="col-sm-12 my-5">
                    <h5 className="bannerSubHeading text-center">Our Benefits</h5>
                    <h1 className="fw-bold my-4 text-center">We're Best Known For</h1>
                </div>
                <div className="col-sm-4 my-5">
                    <div className='text-center'><i className="fa fa-star fa-5x" aria-hidden="true" style={{ "color": "#00ad66" }}></i></div>
                    <h3 className='text-center my-3'>Huge Talent Pool</h3>
                    <p className="paragraphStyle text-center my-3">130 000 + candidates in our database. Top talents with different stacks for all types of companies and products</p>
                </div>
                <div className="col-sm-4 my-5">
                    <div className='text-center'><i className="fa fa-star fa-5x" aria-hidden="true" style={{ "color": "#005ba6" }}></i></div>
                    <h3 className='text-center my-3'>Experience</h3>
                    <p className="paragraphStyle text-center my-3">We prescreen candidates with our unique methodology and use 30+ channels for recruiting</p>
                </div>
                <div className="col-sm-4 my-5">
                    <div className='text-center'><i className="fa fa-star fa-5x" aria-hidden="true" style={{ "color": "#00ad66" }}></i></div>
                    <h3 className='text-center my-3'>Transparency</h3>
                    <p className="paragraphStyle text-center my-3">Live access to CRM during the recruiting process, so you can review the recruiting funnel</p>
                </div>
                <div className="col-sm-4 my-5">
                    <div className='text-center'><i className="fa fa-star fa-5x" aria-hidden="true" style={{ "color": "#005ba6" }}></i></div>
                    <h3 className='text-center my-3'>Insights</h3>
                    <p className="paragraphStyle text-center my-3">We conduct technical interviews. Also, our recruiters give an extensive summary after each interview</p>
                </div>
                <div className="col-sm-4 my-5">
                    <div className='text-center'><i className="fa fa-star fa-5x" aria-hidden="true" style={{ "color": "#00ad66" }}></i></div>
                    <h3 className='text-center my-3'>Flexible Payments</h3>
                    <p className="paragraphStyle text-center my-3">Different tariff plans to choose from and a variety of payment methods from wire to crypto transfers</p>
                </div>
                <div className="col-sm-4 my-5">
                    <div className='text-center'><i className="fa fa-star fa-5x" aria-hidden="true" style={{ "color": "#005ba6" }}></i></div>
                    <h3 className='text-center my-3'>Supervision</h3>
                    <p className="paragraphStyle text-center my-3">We provide a personal manager and professional recruiter for each client</p>
                </div>
            </div>
            <div className='row'>
                <Testimonial />
            </div>
            <div className='row'>
                <Ready />
            </div>
        </div>
    )
}
