import { useState, useEffect } from "react";
import { UserAuthenticationDetails } from "./context/userContext";
import { HomePage } from "./components/webSitePages/home";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { Login } from "./components/webSitePages/login";
import { AssociatePage } from "./components/associate/associatePage";
import axios from "axios";
function App() {
	const [user, setUser] = useState(() => {
		const userData = sessionStorage.getItem('user');
		return userData ? JSON.parse(userData) : null;
	});
	const [token, setToken] = useState(() => {
		const tokenData = sessionStorage.getItem('token');
		return tokenData ? tokenData : null;
	});

	const [loader, setLoader] = useState(false);

	const [menuBarUrl, setMenuBarUrl] = useState([]);
	const axiosURL = 'https://talentcenternetwork.com/tcnAPI/api/api.php';
	const bacEndUrl = 'https://talentcenternetwork.com/tcnAPI/';
	// const bacEndUrl = 'http://localhost/PHP ALL PROJECT/PROJECT_FRLNC/BULTAMINDS/userManagementSystem/';
	// const axiosURL = 'http://localhost/PHP ALL PROJECT/PROJECT_FRLNC/BULTAMINDS/userManagementSystem/api/api.php';

	useEffect(() => {
		axios({
			url: axiosURL,
			method: "POST",
			data: { action: "viewAllUserPermission" },
			headers: {
				'Content-type': 'multipart/form-data'
			}
		}).then((res) => {
			// console.log(res.data);
			const roleCount = res.data['count'];
			if (roleCount > 0) {
				setMenuBarUrl(res.data['data'])
			}
		}).catch((error) => {
			console.log(error);
		})
	}, [axiosURL])

	return (
		<>
			{loader ? (
				<div className="loader-container" id="loader">
					<div className="custom-loader"></div>
				</div>
			) : null}


			<UserAuthenticationDetails.Provider value={{ user, setUser, token, setToken, axiosURL, bacEndUrl, setLoader }}>
				<BrowserRouter>
					<Switch>
						{menuBarUrl.map((data, key) => {
							var path = "/" + data['permission_path'] + "/dashboard";
							return <Route path={path} component={AssociatePage} key={key} />
						})}
						<Route path="/login" component={Login} />
						<Route path="/" component={HomePage} />
					</Switch>
				</BrowserRouter>
			</UserAuthenticationDetails.Provider>
		</>
	);
}

export default App;
